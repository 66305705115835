import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Meeting/';

const getAllMeetings = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllMeetings`, constants.REQUIRE_INTERCEPTORS);

const getAllActiveMeetings = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllActiveMeetings`, constants.REQUIRE_INTERCEPTORS);

const getMeetingById = async (meetingId) => axiosInstance.get(`${ENDPOINTORIGIN}GetMeetingById?meetingId=${meetingId}`, constants.REQUIRE_INTERCEPTORS);

const createMeeting = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}CreateMeeting`, model, constants.REQUIRE_INTERCEPTORS);

const updateMeeting = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateMeeting`, model, constants.REQUIRE_INTERCEPTORS);

const deleteMeeting = async (meetingId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteMeeting?meetingId=${meetingId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllMeetings,
  getAllActiveMeetings,
  getMeetingById,
  createMeeting,
  updateMeeting,
  deleteMeeting
};