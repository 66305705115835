/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-undef */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoPersonSharp } from 'react-icons/io5';
import {
  FiEye,
  FiEyeOff
} from 'react-icons/fi';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import ModalBlock from '../../../../components/ModalBlock';
import InputBlock from '../../../../components/InputBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import OverLay from '../../../../components/Overlay';
import Image from '../../../../components/Image';
import FileUploadBlock from '../../../../components/FileUploadBlock';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';
// HELPERS AND SERVICES
import * as userService from '../../../../services/management/userService';
import * as helper from '../../../../helpers/helper';
import RegisterUserValidator from '../../../../helpers/validators/user/RegisterUserValidator';
import UpdatePasswordValidator from '../../../../helpers/validators/user/UpdatePasswordValidator';
import EditUserValidator from '../../../../helpers/validators/user/EditUserValidator';
import * as settingService from '../../../../services/management/settingService';

const SettingsListingPage = (props) => {
  const { showAlert, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // SETTINGS
  const [settings, setSettings] = useState([]);
  const [selectedSetting, setSelectedSetting] = useState({});
  // GENERAL
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = () => {
    setIsLoading(true);
    settingService.getSettings().then((res) => {
      setSettings(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const formik = useFormik({
    initialValues: {
      key: '',
      value: ''
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    const updatedSetting = { ...selectedSetting, value: values.value };

    settingService.saveSetting(updatedSetting)
      .then(() => {
        showAlert({ text: `${updatedSetting.key} updated successfully`, state: 'success' });
        getSettings();
        handleModalClose();
      })
      .catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      });
  };

  const getSetting = (id) => {
    setIsLoading(true);
    settingService.getSettingById(id).then((res) => {
      setSelectedSetting(res);
      formik.setValues({ key: res.key, value: res.value });
      setIsModalVisible(true);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleModalClose = () => {
    setSelectedSetting({});
    setIsModalVisible(false);
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="Edit"
              className="primary--bg ml-0 size-xxxs"
              size="xxs"
              onClick={() => getSetting(settings[dataIndex].id)}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'key',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'value',
      label: 'Value',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            maxWidth: '500px',
            wordWrap: 'break-word',
          },
        }),
      },
    },
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}

      {/* FEATURES */}
      {/* <CustomBlock className="content-container mb-10">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">

              <CustomBlock className="mb-30">
                <h4 className="size-lg primary--clr fw-700 mb-5">Features</h4>
                <p className="light-grey--clr">Manage Accessibility of Website Features</p>
              </CustomBlock>

              <CustomBlock className="d-flex flex-column gap-3 mb-30">
                <CheckboxBlock
                  disabled
                  label="Contact Page"
                  id="isContactPageActive"
                  {...formik.getFieldProps('isContactFormActive')}
                  isChecked={formik.values.isContactFormActive}
                  labelClassName="d-flex align-items-center size-sm fw-500 m-0"
                  style={{ width: 'fit-content' }}
                />
                <CheckboxBlock
                  disabled
                  label="Events Management"
                  id="isEventsManagementActive"
                  {...formik.getFieldProps('isEventsManagementActive')}
                  isChecked={formik.values.isEventsManagementActive}
                  labelClassName="d-flex align-items-center size-sm fw-500 m-0"
                  style={{ width: 'fit-content' }}
                />
                <CheckboxBlock
                  disabled
                  label="Member Management"
                  id="isMemberManagementActive"
                  {...formik.getFieldProps('isMemberManagementActive')}
                  isChecked={formik.values.isMemberManagementActive}
                  labelClassName="d-flex align-items-center size-sm fw-500 m-0"
                  style={{ width: 'fit-content' }}
                />
              </CustomBlock>

              <CustomBlock className="d-flex justify-content-end">
                <Button
                  isDisabled
                  type="submit"
                  text="Save Changes"
                  className="btn success--bg"
                />
              </CustomBlock>

            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock> */}

      {/* SETTINGS */}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <CustomBlock className="mb-30">
                <h4 className="size-lg primary--clr fw-700 mb-5">Settings</h4>
                <p className="light-grey--clr">View and Manage Website Settings</p>
              </CustomBlock>

              <CustomBlock>
                <MUIDataTable
                  data={settings}
                  columns={columns}
                  options={{
                    selectableRows: 'none',
                    download: false,
                    print: false,
                    jumpToPage: false,
                    search: false,
                    viewColumns: false,
                    filter: false,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } }
                  }}
                />
              </CustomBlock>

            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* UPDATE MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isModalVisible}
        size="md"
        contentHeader="Update Setting"
        primaryModalActionText="Save Changes"
        primaryModalActionColor="success--bg"
        primaryModalActionOnClick={formik.submitForm}
        onHide={handleModalClose}
      >
        <FormBlock onSubmit={formik.submitForm}>
          <Section hasNoContainer>
            <ContentBlock>
              <InputBlock
                isDisabled
                label="Name"
                errorMessage={formik.errors.key}
                inputState={`${helper.getInputClasses(formik, 'key')}`}
                {...formik.getFieldProps('key')}
                className="disabled border-0"
              />
            </ContentBlock>

            <ContentBlock>
              <InputBlock
                label="Value"
                errorMessage={formik.errors.value}
                inputState={`${helper.getInputClasses(formik, 'value')}`}
                {...formik.getFieldProps('value')}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alert.actions })(SettingsListingPage);