// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';

const FixedActionsBar = (props) => {
  const {
    primaryActionText,
    primaryActionColor,
    primaryActionTo,
    isPrimaryActionDisabled,
    primaryActionOnClick,
    secondaryActionText,
    secondaryActionColor,
    secondaryActionTo,
    isSecondaryActionDisabled,
    secondaryActionOnClick,
    optionalActionText,
    optionalActionColor,
    optionalActionTo,
    optionalActionOnClick,
  } = props;

  return (
    <CustomBlock className="fixed-actions-bar">
      <CustomBlock className="left-action-block small-bar">
        <CustomBlock className="input-item action">
          {
            (optionalActionText !== '' || null)
            && (
              <Button
                text={optionalActionText}
                className={optionalActionColor}
                to={optionalActionTo}
                onClick={optionalActionOnClick}
              />
            )
          }
        </CustomBlock>
      </CustomBlock>

      <CustomBlock className="content-container--actions small-bar">
        {
          (secondaryActionText !== '' || null)
          && (
            <Button
              text={secondaryActionText}
              className={secondaryActionColor}
              to={secondaryActionTo}
              onClick={secondaryActionOnClick}
              isDisabled={isSecondaryActionDisabled}
            />
          )
        }

        <Button
          text={primaryActionText}
          className={primaryActionColor}
          to={primaryActionTo}
          onClick={primaryActionOnClick}
          isDisabled={isPrimaryActionDisabled}
        />
      </CustomBlock>
    </CustomBlock>
  );
};

FixedActionsBar.propTypes = {
  // ACTION PROPS
  primaryActionText: PropTypes.string.isRequired,
  primaryActionColor: PropTypes.string,
  primaryActionTo: PropTypes.string,
  primaryActionOnClick: PropTypes.func,
  isPrimaryActionDisabled: PropTypes.bool,

  secondaryActionText: PropTypes.string,
  secondaryActionColor: PropTypes.string,
  secondaryActionTo: PropTypes.string,
  secondaryActionOnClick: PropTypes.func,
  isSecondaryActionDisabled: PropTypes.bool,

  optionalActionText: PropTypes.string,
  optionalActionColor: PropTypes.string,
  optionalActionTo: PropTypes.string,
  optionalActionOnClick: PropTypes.func,
};

FixedActionsBar.defaultProps = {
  // MAIN PROPS
  primaryActionColor: 'primary--bg',
  primaryActionTo: '',
  primaryActionOnClick: null,
  isPrimaryActionDisabled: false,

  secondaryActionText: '',
  secondaryActionColor: 'danger--bg',
  secondaryActionTo: '',
  secondaryActionOnClick: null,
  isSecondaryActionDisabled: false,

  optionalActionText: '',
  optionalActionColor: 'secondary--bg',
  optionalActionTo: '',
  optionalActionOnClick: null,
};

export default FixedActionsBar;