/* eslint-disable import/prefer-default-export */

import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Home/';

const getCMSInfo = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetCMSInfo`, constants.REQUIRE_INTERCEPTORS);

const getDynamicPage = async (dynamicPageId) => axiosInstance.get(`${ENDPOINTORIGIN}GetDynamicPage?dynamicPageId=${dynamicPageId}`, constants.REQUIRE_INTERCEPTORS);

const getDynamicBlog = async (dynamicPageId) => axiosInstance.get(`${ENDPOINTORIGIN}GetDynamicBlog?dynamicPageId=${dynamicPageId}`, constants.REQUIRE_INTERCEPTORS);

const searchProduct = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}SearchProduct`, model, constants.REQUIRE_INTERCEPTORS);

const searchProductByKeyword = async (keyword) => axiosInstance.get(`${ENDPOINTORIGIN}SearchProductByKeyword?keyword=${keyword}`, constants.REQUIRE_INTERCEPTORS);

export {
  getCMSInfo,
  getDynamicPage,
  getDynamicBlog,
  searchProduct,
  searchProductByKeyword
};