// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';

const FormBlock = (props) => {
  const {
    className,
    style,
    children,
    onSubmit
  } = props;
  return (
    <form
      className={`form-block-container ${className}`}
      onSubmit={onSubmit}
      style={style}
    >
      {children}
    </form>
  );
};

FormBlock.propTypes = {
  // MAIN PROPS
  className: PropTypes.string,
  children: PropTypes.any,

  // FORM ACTION PROPS
  onSubmit: PropTypes.func,
};

FormBlock.defaultProps = {
  // MAIN PROPS
  className: '',
  children: null,

  // FORM ACTION PROPS
  onSubmit: () => { },
};

export default FormBlock;