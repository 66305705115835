// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
import { useSpring } from 'react-spring';
// COMPONENTS
import { AiOutlineLoading } from 'react-icons/ai';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const Overlay = (props) => {
  const {
    isContained,
    overlayDarkness,
    onClick,
    hasLoader,
  } = props;

  const overlayDarknessOptions = {
    normal: 'normal',
    dark: 'dark'
  };

  const animateShow = useSpring({ opacity: 1, from: { opacity: 0 } });
  const loader = (<AiOutlineLoading className="loading-indicator rotating white--clr" size={40} />);

  return (
    <>
      {
        !isContained
          ? (
            <button
              className={`overlay ${overlayDarkness && overlayDarknessOptions[overlayDarkness]}`}
              style={animateShow}
              onClick={onClick}
            >
              {hasLoader && loader}
            </button>
          )
          : (
            <CustomBlock className="flex-center">
              {hasLoader && loader}
            </CustomBlock>
          )
      }
    </>
  );
};

Overlay.propTypes = {
  // MAIN PROPS
  isContained: PropTypes.bool,
  overlayDarkness: PropTypes.string,
  onClick: PropTypes.func,
  hasLoader: PropTypes.bool,
};

Overlay.defaultProps = {
  // MAIN PROPS
  isContained: false,
  overlayDarkness: 'normal',
  onClick: (() => {}),
  hasLoader: false,
};

export default Overlay;