/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-awesome-reveal';
// ICONS
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
// COMPONENTS
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
// HERLPERS & SERVICES

const Accordion = (props) => {
  const {
    containerClassName,
    className,
    headerClasses,
    bodyClasses,
    data
  } = props;
  const [fetchedData, setFetchedData] = useState([]);
  const [openItemId, setOpenItemId] = useState(null);

  useEffect(() => {
    if (data) {
      setFetchedData(data);
    }
  }, []);

  const toggleItem = (id) => {
    setOpenItemId(openItemId === id ? null : id);
  };

  return (
    <CustomBlock className={`accordion-container ${className || ''}`}>
      {fetchedData.map((item) => (
        <Fade cascade direction="right">
          <CustomBlock key={item.id} className="item-wrapper">
            {/* HEADER */}
            <CustomBlock
              className="header"
              onClick={() => toggleItem(item.id)}
            >
              <p className={`${headerClasses}`}>
                {item.header}
              </p>

              {openItemId === item.id ? (
                <AiOutlineMinus
                  size="30"
                  className="accordion-toggle-icon light--clr"
                />
              ) : (
                <AiOutlinePlus
                  size="30"
                  className="accordion-toggle-icon light--clr"
                />
              )}
            </CustomBlock>

            {/* BODY */}
            <CustomBlock className={`body ${bodyClasses} ${openItemId === item.id ? 'open' : ''}`}>
              <p>{item.body}</p>
            </CustomBlock>
          </CustomBlock>

          {/* ACCORDION ITEM SPACER */}
          <hr className="lightest--sbg" />
        </Fade>
      ))}
    </CustomBlock>
  );
};

Accordion.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  headerClasses: PropTypes.string,
  bodyClasses: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      header: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    })
  ).isRequired,
};

Accordion.defaultProps = {
  containerClassName: '',
  className: '',
  headerClasses: '',
  bodyClasses: '',
};

export default Accordion;