import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Blog/';

const getAllBlogs = async (isActive) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllBlogs?isActive=${isActive}`, constants.REQUIRE_INTERCEPTORS);

const getBlogById = async (blogId) => axiosInstance.get(`${ENDPOINTORIGIN}GetBlogById?blogId=${blogId}`, constants.REQUIRE_INTERCEPTORS);

const createBlog = async (blog) => axiosInstance.post(`${ENDPOINTORIGIN}CreateBlog`, blog, constants.REQUIRE_INTERCEPTORS);

const updateBlog = async (blog) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateBlog`, blog, constants.REQUIRE_INTERCEPTORS);

const deleteBlog = async (blogId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteBlog?id=${blogId}`, constants.REQUIRE_INTERCEPTORS);

const assignContentSectionToBlog = async (blogId, contentSectionId, type) => axiosInstance.post(`${ENDPOINTORIGIN}AssignContentSectionToBlog?blogId=${blogId}&contentSectionId=${contentSectionId}&type=${type}`, {}, constants.REQUIRE_INTERCEPTORS);

const unassignContentSection = async (contentSectionBannerId) => axiosInstance.delete(`${ENDPOINTORIGIN}UnassignContentSection?contentSectionBannerId=${contentSectionBannerId}`, constants.REQUIRE_INTERCEPTORS);

const sortBlogContentSections = async (sortOrders) => axiosInstance.put(`${ENDPOINTORIGIN}SortBlogContentSections`, sortOrders, constants.REQUIRE_INTERCEPTORS);

const deleteBannerImage = async (blogId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteBannerImage?blogId=${blogId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllBlogs,
  getBlogById,
  createBlog,
  updateBlog,
  deleteBlog,
  assignContentSectionToBlog,
  unassignContentSection,
  sortBlogContentSections,
  deleteBannerImage
};