// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import TextBlock from './TextBlock';

const Image = (props) => {
  const {
    to,
    onClick,
    containerClassName,
    className,
    hasRoundCorners,
    source,
    alt,
    title
  } = props;

  const history = useHistory();

  const navigateToPath = (path) => {
    history.push(path);
  };

  return (
    <>
      {
        (to !== '' || null || onClick)
          ? (
            <button
              onClick={to ? () => navigateToPath(to) : onClick}
              className={containerClassName}
              type="button"
            >
              <img
                className={`image${hasRoundCorners ? '--rounded' : ''} ${className}`}
                src={source}
                alt={alt}
              />

              {
                title !== ''
                && (
                  <TextBlock
                    type="description"
                    text={title}
                    position="center"
                    className="image-title"
                  />
                )
              }
            </button>
          ) : (
            <CustomBlock className={containerClassName}>
              <img
                className={`image${hasRoundCorners ? '--rounded' : ''} ${className}`}
                src={source}
                alt={alt}
              />

              {
                title !== ''
                && (
                  <TextBlock
                    type="description"
                    text={title}
                    position="center"
                    className="image-title"
                  />
                )
              }
            </CustomBlock>
          )
      }
    </>
  );
};

Image.propTypes = {
  // MAIN PROPS
  to: PropTypes.string,
  onClick: PropTypes.func,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  hasRoundCorners: PropTypes.bool,
  source: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
};

Image.defaultProps = {
  // MAIN PROPS
  to: '',
  alt: 'Image',
  title: '',
  onClick: null,
  containerClassName: '',
  className: '',
  hasRoundCorners: false,
};

export default Image;