import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'MenuItem/';

const getAllMenuItems = async (isActive) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllMenuItems?isActive=${isActive}`, constants.REQUIRE_INTERCEPTORS);

const getMenuItemsById = async (menuItemId) => axiosInstance.get(`${ENDPOINTORIGIN}GetMenuItemById?menuItemId=${menuItemId}`, constants.REQUIRE_INTERCEPTORS);

const getMenuItemsWithNoParentOrChild = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetMenuItemsWithNoParentOrChild`, constants.REQUIRE_INTERCEPTORS);

const createMenuItem = async (menuItem) => axiosInstance.post(`${ENDPOINTORIGIN}CreateMenuItem`, menuItem, constants.REQUIRE_INTERCEPTORS);

const deleteMenuItem = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteMenuItem?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const updateMenuItems = async (menuItem) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateMenuItems`, menuItem, constants.REQUIRE_INTERCEPTORS);

const removeParentMenuItem = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}RemoveParentMenuItem?menuItemId=${id}`, constants.REQUIRE_INTERCEPTORS);

const assignParentMenuItem = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}AssignParentMenuItem`, model, constants.REQUIRE_INTERCEPTORS);

const sortMenuItems = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}SortMenuItems`, model, constants.REQUIRE_INTERCEPTORS);

const sortChildMenuItems = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}SortChildMenuItems`, model, constants.REQUIRE_INTERCEPTORS);

export {
  getAllMenuItems,
  getMenuItemsById,
  createMenuItem,
  deleteMenuItem,
  updateMenuItems,
  removeParentMenuItem,
  assignParentMenuItem,
  getMenuItemsWithNoParentOrChild,
  sortMenuItems,
  sortChildMenuItems
};