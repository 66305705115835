import * as Yup from 'yup';

export default Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required('Menu Item Name is required').max(100),
  pageId: Yup.number().nullable(),
  parentMenuItemId: Yup.number().nullable(),
  customLink: Yup.string().nullable().max(250),
  openInNewTab: Yup.bool(),
  isActive: Yup.bool(),
});