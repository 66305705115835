/* eslint-disable default-param-last */
export const actionTypes = {
  ShowAlert: 'SHOW_ALERT',
  HideAlert: 'HIDE_ALERT',
};

const initialAlertState = {
  visible: false,
  text: '',
  state: 'success',
};

export const reducer = (state = initialAlertState, action) => {
  switch (action.type) {
    case actionTypes.ShowAlert: {
      return {
        visible: true,
        text: action.payload.text,
        state: action.payload.state,
      };
    }

    case actionTypes.HideAlert: {
      return {
        ...state,
        visible: false
      };
    }

    default:
      return state;
  }
};

export const actions = {
  showAlert: (model) => ({ type: actionTypes.ShowAlert, payload: model }),
  hideAlert: () => ({ type: actionTypes.HideAlert }),
};