/* eslint-disable max-len */
// DEPENDENCIES
import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
// import { IoMapOutline } from 'react-icons/io5';
import { LuMap, LuSprout } from 'react-icons/lu';
// COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Button from '../../../components/Button';

const OurServiceRegions = () => {
  const [selectedMap, setSelectedMap] = useState('publicMap');

  const buttonStyle = (isSelected) => ({
    filter: isSelected ? 'none' : 'opacity(0.4) grayscale(1)',
    transition: 'filter 0.3s ease'
  });

  return (
    <CustomBlock className="content-section">
      <CustomBlock className="service-areas-section">
        {/* CONTAINER 1 */}
        <CustomBlock className="container-1">
          <Fade cascade>
            <h3 className="header size-xl fw-700 dark-grey--clr pb-10">Our Service Regions</h3>
            <p className="description mid-grey--clr size-mdlg">
              {selectedMap === 'publicMap'
                ? 'Explore service areas or search addresses covered by Gravity Drainage District 2.'
                : 'View capital projects and infrastructure improvements.'}
            </p>
          </Fade>

          <CustomBlock className="d-flex flex-wrap gap-20 mt-30">
            <Fade
              cascade
              direction="up"
              delay={800}
            >
              <Button
                hasRoundCorners
                type="button"
                text="Public Map"
                onClick={() => setSelectedMap('publicMap')}
                iconLeft={<LuMap className="" size="22" />}
                className="secondary--sbg size-xs pl-30 pr-30"
                style={buttonStyle(selectedMap === 'publicMap')}
              />
              <Button
                hasRoundCorners
                type="button"
                text="Capital Projects"
                onClick={() => setSelectedMap('capitalOutlay')}
                iconLeft={<LuSprout className="" size="22" />}
                className="primary--sbg size-xs pl-30 pr-30"
                style={buttonStyle(selectedMap === 'capitalOutlay')}
              />
            </Fade>
          </CustomBlock>
        </CustomBlock>

        {/* CONTAINER 2 */}
        <CustomBlock className="container-2">
          <Fade>
            <>
              {selectedMap === 'publicMap' && (
                <CustomBlock className="w-100 vh-100">
                  <iframe
                    src="https://lpgdd2.maps.arcgis.com/apps/webappviewer/index.html?id=bf1fd330f6934a8a8df1cc02beb03f75"
                    title="Map Viewer"
                    className="w-100 h-100 overflow-hidden round"
                    style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
                  />
                </CustomBlock>
              )}
              {selectedMap === 'capitalOutlay' && (
                <CustomBlock className="w-100 vh-100">
                  <iframe
                    src="https://www.arcgis.com/apps/dashboards/18c8610e97b34d4ba9d4584c497e5ca3"
                    title="Map Viewer"
                    className="w-100 h-100 overflow-hidden round"
                    style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
                  />
                </CustomBlock>
              )}
            </>
          </Fade>
        </CustomBlock>
      </CustomBlock>
    </CustomBlock>
  );
};

export default OurServiceRegions;