/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import ContactForm from '../../../components/ContactForm';
// SERVICES AND HELPERS
import * as auth from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';
import * as emailService from '../../../services/management/emailService';

const ReportIssueTab = (props) => {
  const { showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const formConfig = [
    { name: 'firstName', label: 'First Name', required: true, placeholder: 'John', max: 100 },
    { name: 'lastName', label: 'Last Name', required: true, placeholder: 'Smith', max: 100 },
    { name: 'email', label: 'Email Address', required: true, placeholder: 'johnsmith@example.com', max: 100, email: true },
    { name: 'phoneNumber', label: 'Phone Number', placeholder: '(555) 555-5555', max: 50 },
    { name: 'address', label: 'Address', placeholder: 'location of Issue', max: 50 },
    { name: 'waterway', label: 'Waterway', placeholder: 'Name of Waterway (If Known)', max: 50 },
    { name: 'message', label: 'Issue Description', required: true, placeholder: 'Provide details about the problem or issue here...', textarea: true, cols: 12 },
  ];

  const submitForm = async (values, { resetForm }) => {
    setIsLoading(true);

    const submissionData = {
      ...values,
      formType: 'report-issue-form',
    };

    await emailService.sendEmail(submissionData).then(() => {
      showAlert({ text: 'Success! Your message has been received.', state: 'success' });
      resetForm();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <CustomBlock className="mb-40">
        <h2 className="size-xl fw-700 primary--clr mb-15">Report An Issue</h2>
        <p className="mid-grey--clr size-mdlg">Use the form below to report any drainage problem or issue to <span className="fw-500">Livingston Parish Gravity Drainage District № 2</span>.</p>
      </CustomBlock>

      {/* REPORT ISSUE FORM */}
      <ContactForm
        formConfig={formConfig}
        formType="report-issue-form"
        onSubmit={(values, actions) => submitForm(values, actions)}
        isSubmitting={isLoading}
        submitButtonLabel="Send Report"
        submitButtonClasses="primary--sbg"
      />
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alertToast.actions
})(ReportIssueTab);