/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
// DEPENDENCIES
import React from 'react';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';
// ASSETS
// import Logo from '../assets/img/logo_alt-light-bg.svg';
import BannerImage from '../assets/img/homepage-banner-image_default.png';

const quickActions = [
  {
    text: 'About Us',
    to: '/about-livingston-parish-drainage-district-2',
    className: 'light--clr fw-600 hover-2',
    isTextUppercase: true,
    type: 'button',
  },
  {
    text: 'Report An Issue',
    to: '/contact-livingston-parish-drainage-district-2',
    className: 'light--clr fw-600 hover-1',
    isTextUppercase: true,
    type: 'button',
  },
];

const CustomBannerInsert = (props) => {
  const { offsetValue } = props;

  return (
    <CustomBlock
      className="custom-banner"
      style={{
        backgroundImage: `url(${BannerImage})`,
        backgroundPositionY: `${offsetValue * 0.25}px`,
        backgroundPositionX: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <CustomBlock className="custom-banner__secondary">
        <Fade delay={400}>
          <CustomBlock className="img-container" />
        </Fade>
      </CustomBlock>

      <CustomBlock className="custom-banner__primary">
        <CustomBlock className="header-container">
          <Fade cascade direction="up">
            <h2 className="size-mdlg primary--clr">Livingston Parish</h2>
            <h1 className="size-xl fw-800 primary--clr">Gravity Drainage</h1>
            <h1 className="size-lg fw-800 primary--clr">District 2</h1>
          </Fade>
        </CustomBlock>

        <CustomBlock className="description-container">
          <Fade>
            <p className="dark-grey--clr size-mdlg fw-500">
              Maintaining waterways, ensuring safety and beauty within our community since 1994.
            </p>
          </Fade>
        </CustomBlock>

        <CustomBlock className="actions-container mb-30">
          <Fade cascade direction="right" delay={800}>
            {quickActions.map((action) => (
              <Button
                isTextUppercase={action.isTextUppercase}
                type={action.type}
                text={action.text}
                to={action.to}
                className={action.className}
              />
            ))}
          </Fade>
        </CustomBlock>
      </CustomBlock>

      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className="abs-decoration--wave light--clr"
        fill="current-color"
      >
        <path
          // fill="#FFF"
          fill="#F3F4F6"
          fillOpacity="1"
          d="M0,128L80,149.3C160,171,320,213,480,208C640,203,800,149,960,133.3C1120,117,1280,139,1360,149.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        />
      </svg> */}

      <svg
        viewBox="0 0 1440 193"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="abs-decoration--wave light--clr"
      >
        <path d="M0 0.999859L80 22.2999C160 43.9999 320 85.9999 480 80.9999C640 75.9999 800 21.9999 960 6.29986C1120 -10.0001 1280 11.9999 1360 22.2999L1440 32.9999V193H1360C1280 193 1120 193 960 193C800 193 640 193 480 193C320 193 160 193 80 193H0V0.999859Z" fill="#F3F4F6" />
      </svg>
    </CustomBlock>
  );
};

export default CustomBannerInsert;