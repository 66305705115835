import * as Yup from 'yup';

export default Yup.object().shape({
  id: Yup.number(),
  type: Yup.string().required(),
  title: Yup.string().nullable().max(1000),
  name: Yup.string().nullable().max(1000),
  description: Yup.string().nullable(),
  alignment: Yup.string().required(),
  imagePath: Yup.string(),
  isPadded: Yup.bool(),
  isFloatingCard: Yup.bool(),
  hasRoundedCorners: Yup.bool(),
  isActive: Yup.bool(),
  isTextOnly: Yup.bool(),
  primaryButtonText: Yup.string().nullable().max(50),
  primaryButtonActionType: Yup.string().nullable().when('primaryButtonText', {
    is: (primaryButtonText) => !primaryButtonText || primaryButtonText.length === 0,
    then: Yup.string().nullable().max(500),
    otherwise: Yup.string().nullable().max(500).required('Primary Action Type is required')
  }),
  primaryButtonLink: Yup.string().nullable().max(500).when('primaryButtonText', {
    is: (primaryButtonText) => !primaryButtonText || primaryButtonText.length === 0,
    then: Yup.string().nullable().max(500),
    otherwise: Yup.string().nullable().max(500).required('Primary Action Link is required')
  }),
  secondaryButtonText: Yup.string().nullable().max(50),
  secondaryButtonActionType: Yup.string().nullable().when('secondaryButtonText', {
    is: (secondaryButtonText) => !secondaryButtonText || secondaryButtonText.length === 0,
    then: Yup.string().nullable().max(500),
    otherwise: Yup.string().nullable().max(500).required('Secondary Action Type is required')
  }),
  secondaryButtonLink: Yup.string().nullable().max(500).when('secondaryButtonText', {
    is: (secondaryButtonText) => !secondaryButtonText || secondaryButtonText.length === 0,
    then: Yup.string().nullable().max(500),
    otherwise: Yup.string().nullable().max(500).required('Secondary Action Link is required')
  }),
}, [['type', 'primaryButtonText', 'secondaryButtonActionText']]);