/* eslint-disable import/no-unresolved */
// DEPENDENCIES
import React from 'react';
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import Routes from './Routes';
// CUSTOM COMPONENTS
// STYLESHEETS
import 'react-table/react-table.css';
import './assets/css/main.css';
import './assets/custom/custom.css';

const App = () => (
  <HttpsRedirect>
    <BrowserRouter>
      <ParallaxProvider>
        <Routes />
      </ParallaxProvider>
    </BrowserRouter>
  </HttpsRedirect>
);

export default App;