import * as Yup from 'yup';

export default Yup.object().shape({
  oldPassword: Yup.string()
    .required('Old password is required')
    .min(8, 'Minimum length of Password is 8')
    .max(100),
  password: Yup.string()
    .required('New password is required')
    .min(8, 'Minimum length of Password is 8')
    .max(100),
  confirmPassword: Yup.string()
    .required('Re-enter your new password')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});