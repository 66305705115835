import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Page/';

const getAllPages = async (isActive) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllPages?isActive=${isActive}`, constants.REQUIRE_INTERCEPTORS);

const getPageById = async (pageId) => axiosInstance.get(`${ENDPOINTORIGIN}GetPageById?pageId=${pageId}`, constants.REQUIRE_INTERCEPTORS);

const createPage = async (page) => axiosInstance.post(`${ENDPOINTORIGIN}CreatePage`, page, constants.REQUIRE_INTERCEPTORS);

const updatePage = async (page) => axiosInstance.put(`${ENDPOINTORIGIN}UpdatePage`, page, constants.REQUIRE_INTERCEPTORS);

const deletePage = async (pageId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeletePage?id=${pageId}`, constants.REQUIRE_INTERCEPTORS);

const assignContentSectionToPage = async (pageId, contentSectionId, type) => axiosInstance.post(`${ENDPOINTORIGIN}AssignContentSectionToPage?pageId=${pageId}&contentSectionId=${contentSectionId}&type=${type}`, {}, constants.REQUIRE_INTERCEPTORS);

const unassignContentSection = async (contentSectionBannerId) => axiosInstance.delete(`${ENDPOINTORIGIN}UnassignContentSection?contentSectionBannerId=${contentSectionBannerId}`, constants.REQUIRE_INTERCEPTORS);

const sortPageContentSections = async (sortOrders) => axiosInstance.put(`${ENDPOINTORIGIN}SortPageContentSections`, sortOrders, constants.REQUIRE_INTERCEPTORS);

const deleteBannerImage = async (pageId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteBannerImage?pageId=${pageId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllPages,
  getPageById,
  createPage,
  updatePage,
  deletePage,
  assignContentSectionToPage,
  unassignContentSection,
  sortPageContentSections,
  deleteBannerImage
};