// DEPENDECIES
import React from 'react';
import PropTypes from 'prop-types';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import DotIndicator from './DotIndicator';

const TextHeader = (props) => {
  const {
    title,
    size,
    className,
    subtitle,
    subtitleSize,
    subtitleClassName,
    isCentered,
    iconLeft,
    iconLeftClassName,
  } = props;

  const textSize = {
    xl: '--xl',
    lg: '--lg',
    md: '--md',
    mdlg: '--mdlg',
    sm: '--sm',
    xs: '--xs',
  };

  return (
    <CustomBlock className="text-header-container">
      <h4 className={`text-header${size && textSize[size]} ${className} ${isCentered ? 'centered' : ''}`}>
        {
          iconLeft && <CustomBlock className={`text-header-icon-left ${iconLeftClassName}`}>{iconLeft}</CustomBlock>
        }
        {title}
        <DotIndicator {...props} />
      </h4>
      {subtitle && <p className={`text-header-subtitle${subtitleSize && textSize[subtitleSize]} ${subtitleClassName}`}>{subtitle}</p>}
    </CustomBlock>
  );
};

TextHeader.propTypes = {
  // TITLE PROPS
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,

  // SUBTITLE PROPS
  subtitle: PropTypes.string,
  subtitleClassName: PropTypes.string,
  subtitleSize: PropTypes.string,

  // ICON PROPS
  iconLeft: PropTypes.element,
  iconLeftClassName: PropTypes.string,

  // BOOLEAN PROPS
  isCentered: PropTypes.bool,
};

TextHeader.defaultProps = {
  // TITLE PROPS
  className: '',
  size: '',

  // SUBTITLE PROPS
  subtitle: '',
  subtitleClassName: '',
  subtitleSize: '',

  // ICON PROPS
  iconLeft: null,
  iconLeftClassName: '',

  // BOOLEAN PROPS
  isCentered: false,
};

export default TextHeader;