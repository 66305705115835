// DEPENDENCIES
import React, { useState } from 'react';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
// TABS
import ContactUsTab from '../pages/cms/tabs/ContactUsTab';
import ReportIssueTab from '../pages/cms/tabs/ReportIssueTab';

const TabbedInterface = () => {
  const [activeTab, setActiveTab] = useState('contact-us');

  const handleTabSelect = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <CustomBlock
        className="tabs-container"
        id="myTab"
        role="tablist"
      >
        <button
          className={`tab-button ${activeTab === 'contact-us' ? 'active' : 'inactive'} size-mdlg secondary-lighter--clr fw-500`}
          id="contact-us-tab"
          data-bs-toggle="tab"
          data-bs-target="#contact-us-tab-pane"
          type="button"
          role="tab"
          aria-controls="contact-us-tab-pane"
          aria-selected="true"
          onClick={() => handleTabSelect('contact-us')}
        >
          Contact Us
        </button>
        <button
          className={`tab-button ${activeTab === 'report-issue' ? 'active' : 'inactive'} size-mdlg primary-lighter--clr fw-500`}
          id="report-issue-tab"
          data-bs-toggle="tab"
          data-bs-target="#report-issue-tab-pane"
          type="button"
          role="tab"
          aria-controls="report-issue-tab-pane"
          aria-selected="false"
          onClick={() => handleTabSelect('report-issue')}
        >
          Report Issue
        </button>
      </CustomBlock>

      {/* CONTENT */}
      <CustomBlock className="tab-content" id="myTabContent">
        {activeTab === 'contact-us' && (
          <CustomBlock
            className="tab-pane fade show active"
            id="contact-us-tab-pane"
            role="tabpanel"
            aria-labelledby="contact-us-tab"
            tabIndex="0"
          >
            <ContactUsTab />
          </CustomBlock>
        )}

        {activeTab === 'report-issue' && (
          <CustomBlock
            className="tab-pane fade show active"
            id="report-issue-tab-pane"
            role="tabpanel"
            aria-labelledby="report-issue-tab"
            tabIndex="0"
          >
            <ReportIssueTab />
          </CustomBlock>
        )}
      </CustomBlock>
    </>
  );
};

export default TabbedInterface;