import * as Yup from 'yup';

export default Yup.object().shape({
  username: Yup.string().required('Username is required').max(100),
  fullName: Yup.string().required('Full Name is required').max(250),
  email: Yup.string().email('Please enter valid email').required('Email is required').max(250),
  phone: Yup.string().max(20),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Minimum password length must be at least 8 characters')
    .max(100),
  confirmPassword: Yup.string()
    .required('Please confirm your new password')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
  isActive: Yup.bool().nullable(),
  isAdmin: Yup.bool().nullable()
});