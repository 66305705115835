// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// ICONS
import { HiChevronLeft } from 'react-icons/hi';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';

const HeaderBlock = (props) => {
  const {
    hasReturnAction,
    backgroundColor,
    hasBorder,
    hasBottomShadow,
    leftItemText,
    leftItemTextColor,
    centerItemText,
    centerItemTextColor,
    rightItemText,
    rightItemTextColor,
    primaryButtonText,
    primaryButtonTo,
    primaryButtonColor,
    primaryButtonClassName,
    primaryButtonOnClick,
    primaryButtonSize,
    secondaryButtonText,
    secondaryButtonTo,
    secondaryButtonColor,
    secondaryButtonClassName,
    secondaryButtonOnClick,
    secondaryButtonSize,

  } = props;

  const history = useHistory();
  const iconSize = 30;
  const iconColor = 'mid-grey--clr';
  const returnActionIcon = (<HiChevronLeft className={iconColor} size={iconSize} />);

  return (
    <CustomBlock className={`header-block ${backgroundColor} ${hasBorder && 'bordered'} ${hasBottomShadow && 'with-shadow'}`}>
      <CustomBlock className="left-item-block item-block-container">
        {
          hasReturnAction
          && (
            <CustomBlock className="return-action-container">
              <button onClick={() => { history.goBack(); }}>{returnActionIcon}</button>
            </CustomBlock>
          )
        }
        {leftItemText && <p className={`${leftItemTextColor}`}>{leftItemText}</p>}
      </CustomBlock>

      <CustomBlock className="center-item-block item-block-container">
        {centerItemText && <p className={`${centerItemTextColor}`}>{centerItemText}</p>}
      </CustomBlock>

      <CustomBlock className="right-item-block item-block-container">
        {rightItemText && <p className={`${rightItemTextColor}`}>{rightItemText}</p>}

        {
          (primaryButtonText || primaryButtonText)
          && (
            <CustomBlock className="right-item-action-container">
              {
                primaryButtonText
                && (
                  <Button
                    to={primaryButtonTo}
                    text={primaryButtonText}
                    className={`${primaryButtonColor} ${primaryButtonClassName}`}
                    onClick={primaryButtonOnClick}
                    size={primaryButtonSize}

                  />
                )
              }

              {
                secondaryButtonText
                && (
                  <Button
                    to={secondaryButtonTo}
                    text={secondaryButtonText}
                    className={`${secondaryButtonColor} ${secondaryButtonClassName}`}
                    onClick={secondaryButtonOnClick}
                    size={secondaryButtonSize}

                  />
                )
              }
            </CustomBlock>
          )

        }
      </CustomBlock>
    </CustomBlock>
  );
};

// PROP TYPES
HeaderBlock.propTypes = {
  // MAIN PROPS
  hasReturnAction: PropTypes.bool,
  backgroundColor: PropTypes.string,
  hasBorder: PropTypes.bool,
  hasBottomShadow: PropTypes.bool,

  //  LEFT ITEM PROPS
  leftItemText: PropTypes.string,
  leftItemTextColor: PropTypes.string,

  // CENTER ITEM PROPS
  centerItemText: PropTypes.string,
  centerItemTextColor: PropTypes.string,

  // RIGHT ITEM PROPS
  rightItemText: PropTypes.string,
  rightItemTextColor: PropTypes.string,

  // PRIMARY ACTION PROPS
  primaryButtonText: PropTypes.string,
  primaryButtonTo: PropTypes.string,
  primaryButtonClassName: PropTypes.string,
  primaryButtonColor: PropTypes.string,
  primaryButtonSize: PropTypes.string,
  primaryButtonOnClick: PropTypes.func,

  // SECONDARY ACTION PROPS
  secondaryButtonText: PropTypes.string,
  secondaryButtonTo: PropTypes.string,
  secondaryButtonClassName: PropTypes.string,
  secondaryButtonColor: PropTypes.string,
  secondaryButtonSize: PropTypes.string,
  secondaryButtonOnClick: PropTypes.func,
};

// DEFAULT VALUES
HeaderBlock.defaultProps = {
  // MAIN PROPS
  hasReturnAction: false,
  backgroundColor: '',
  hasBorder: false,
  hasBottomShadow: '',

  //  LEFT ITEM PROPS
  leftItemText: '',
  leftItemTextColor: 'secondary--clr',

  // CENTER ITEM PROPS
  centerItemText: '',
  centerItemTextColor: 'secondary--clr',

  // RIGHT ITEM PROPS
  rightItemText: '',
  rightItemTextColor: 'secondary--clr',

  // PRIMARY ACTION PROPS
  primaryButtonText: '',
  primaryButtonTo: null,
  primaryButtonClassName: '',
  primaryButtonColor: 'primary--bg',
  primaryButtonSize: 'xxs',
  primaryButtonOnClick: (() => { }),

  // SECONDARY ACTION PROPS
  secondaryButtonText: '',
  secondaryButtonTo: null,
  secondaryButtonClassName: '',
  secondaryButtonColor: 'secondary--bg',
  secondaryButtonSize: 'xxs',
  secondaryButtonOnClick: (() => { })
};

export default HeaderBlock;