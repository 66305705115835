// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';

const ContentBlock = (props) => {
  const { className, cols, children } = props;
  const columnSizes = {
    12: 'col-lg-12 col-md-12',
    11: 'col-lg-11 col-md-11',
    10: 'col-lg-10 col-md-10',
    9: 'col-lg-9 col-md-9',
    8: 'col-lg-8 col-md-8',
    7: 'col-lg-7 col-md-7',
    6: 'col-lg-6 col-md-6',
    5: 'col-lg-5 col-md-5',
    4: 'col-lg-4 col-md-4',
    3: 'col-lg-3 col-md-3',
    2: 'col-lg-2 col-md-2',
    1: 'col-lg-1 col-md-1',
  };

  return (
    <div className={`${cols && columnSizes[cols]} ${className}`}>
      {children}
    </div>
  );
};

ContentBlock.propTypes = {
  // MAIN PROPS
  cols: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.any
};

ContentBlock.defaultProps = {
  cols: 12,
  className: '',
  children: null
};

export default ContentBlock;