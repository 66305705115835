import * as Yup from 'yup';

export default Yup.object().shape({
  companyName: Yup.string().required('Company Name is required').max(250),
  emailAddress: Yup.string().email().required('Email is required').max(150),
  phoneNumber: Yup.string().nullable().max(20),
  streetAddress1: Yup.string().nullable().max(150),
  streetAddress2: Yup.string().nullable().max(150),
  city: Yup.string().nullable().max(100),
  state: Yup.string().nullable().max(20),
  zipCode: Yup.string().nullable().max(10),
  primaryWeekDayHours: Yup.string().nullable().max(150),
  primaryWeekEndHours: Yup.string().nullable().max(150),
  secondaryWeekDayHours: Yup.string().nullable().max(150),
  secondaryWeekEndHours: Yup.string().nullable().max(150),
  facebookLink: Yup.string().nullable().max(500),
  instagramLink: Yup.string().nullable().max(500),
  twitterLink: Yup.string().nullable().max(500),
  linkedInLink: Yup.string().nullable().max(500),
  tiktokLink: Yup.string().nullable().max(500),
  youtubeLink: Yup.string().nullable().max(500),
  googleMapLink: Yup.string().nullable().max(500),
  appStoreLink: Yup.string().nullable().max(500),
  googlePlayStoreLink: Yup.string().nullable().max(500),
  googleAnalyticsId: Yup.string().nullable().max(500),
});