import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Banner/';

const getAllBanners = async (isActive) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllBanners?isActive=${isActive}`, constants.REQUIRE_INTERCEPTORS);

const getBannerById = async (bannerId) => axiosInstance.get(`${ENDPOINTORIGIN}GetBannerById?bannerId=${bannerId}`, constants.REQUIRE_INTERCEPTORS);

const createBanner = async (banner) => axiosInstance.post(`${ENDPOINTORIGIN}CreateBanner`, banner, constants.REQUIRE_INTERCEPTORS);

const updateBanner = async (banner) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateBanner`, banner, constants.REQUIRE_INTERCEPTORS);

const deleteBanner = async (bannerId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteBanner?id=${bannerId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllBanners,
  getBannerById,
  createBanner,
  updateBanner,
  deleteBanner
};