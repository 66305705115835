// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const DotIndicator = (props) => {
  const { hasDotIndicator, dotIndicatorColor } = props;
  return (
    <>
      {hasDotIndicator && <CustomBlock className={`dot-indicator ${dotIndicatorColor}`} />}
    </>
  );
};

DotIndicator.propTypes = {
  // MAIN PROPS
  hasDotIndicator: PropTypes.bool,
  dotIndicatorColor: PropTypes.string,
};

DotIndicator.defaultProps = {
  // MAIN PROPS
  hasDotIndicator: false,
  dotIndicatorColor: 'danger--sbg',
};

export default DotIndicator;