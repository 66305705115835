// DEPENDENCIES
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const ListBlock = (props) => {
  const {
    title,
    titleColor,
    to,
    type,
    backgroundColor,
    tooltipText,
    tooltipTextColor,
    tooltipBackgroundColor,
    iconLeft,
    iconLeftBadgeText,
    iconLeftBadgeTextColor,
    iconLeftBadgeBackgroundColor,
    iconRight,
    iconRightText,
    iconRightTextColor,
    iconRightBackgroundColor,
    badgeText,
    badgeTextColor,
    badgeColor,
    onClick,
    onDoubleClick,
    isCollapsed,
    hasBorder,
    hasRoundIconRight,
  } = props;

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const history = useHistory();

  const navigateToPath = (path) => {
    history.push(path);
  };

  return (
    <button
      type={type || 'button'}
      onClick={to ? () => navigateToPath(to) : onClick}
      onDoubleClick={onDoubleClick}
      className={`list-block ${isCollapsed ? 'collapsed' : ''} ${backgroundColor || 'no-bg'} ${hasBorder && 'hasBorder'}`}
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      {
        iconLeft
        && (
          <CustomBlock className={`icon-left ${isCollapsed ? 'collapsed' : ''}`}>
            {iconLeft}
            {
              (iconLeftBadgeText || (badgeColor))
              && (
                <CustomBlock className={`icon-left-badge ${iconLeftBadgeBackgroundColor} ${badgeColor ? (`${badgeColor} dot-badge`) : 'danger--sbg'}`}>
                  <p className={`icon-left-badge-text ${iconLeftBadgeTextColor}`}>
                    {iconLeftBadgeText}
                  </p>
                </CustomBlock>
              )
            }

            {
              tooltipText && isCollapsed
              && (
                <CustomBlock className={`list-block-tool-tip ${isTooltipVisible ? 'visible' : 'hidden'} ${tooltipBackgroundColor}`}>
                  <p className={`${tooltipTextColor}`}>
                    {tooltipText}
                  </p>
                </CustomBlock>
              )
            }
          </CustomBlock>
        )
      }

      {
        title
        && (
          <CustomBlock
            className="title"
            style={{ width: !iconLeft ? '80%' : '65%' }}
          >
            <p className={`${titleColor || 'white--clr'}`}>
              {title}
            </p>

            {
              badgeText
              && (
                <CustomBlock className={`title-badge ${badgeColor}`}>
                  <p className={`badge-text ${badgeTextColor}`}>{badgeText}</p>
                </CustomBlock>
              )
            }
          </CustomBlock>
        )
      }

      {
        iconRight
          ? (
            <CustomBlock className="icon-right">
              <CustomBlock className={`${iconRightBackgroundColor} icon-right-container ${hasRoundIconRight && 'round'}`}>
                {iconRight}
              </CustomBlock>
            </CustomBlock>
          )
          : (
            iconRightText
            && (
              <CustomBlock className="icon-right">
                <CustomBlock className={`${iconRightBackgroundColor} icon-right-container text ${hasRoundIconRight && 'round'}`}>
                  <p className={`${iconRightTextColor}`}>
                    {iconRightText}
                  </p>
                </CustomBlock>
              </CustomBlock>
            )
          )
      }
    </button>
  );
};

ListBlock.propTypes = {
  // MAIN PROPS
  title: PropTypes.string,
  titleColor: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string,
  backgroundColor: PropTypes.string,

  // TOOLTIP PROPS
  tooltipText: PropTypes.string,
  tooltipTextColor: PropTypes.string,
  tooltipBackgroundColor: PropTypes.string,

  // ICON LEFT PROPS
  iconLeft: PropTypes.any,
  iconLeftBadgeText: PropTypes.string,
  iconLeftBadgeTextColor: PropTypes.string,
  iconLeftBadgeBackgroundColor: PropTypes.string,

  // ICON RIGHT PROPS
  iconRight: PropTypes.any,
  iconRightText: PropTypes.string,
  iconRightTextColor: PropTypes.string,
  iconRightBackgroundColor: PropTypes.string,

  // BADGE PROPS
  badgeText: PropTypes.string,
  badgeTextColor: PropTypes.string,
  badgeColor: PropTypes.string,

  // FUNCTION PROPS
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,

  // BOOLEAN PROPS
  isCollapsed: PropTypes.bool,
  hasBorder: PropTypes.bool,
  hasRoundIconRight: PropTypes.bool,
};

ListBlock.defaultProps = {
  // MAIN PROPS
  title: '',
  titleColor: 'white--clr',
  to: '',
  type: '',
  backgroundColor: '',

  // TOOLTIP PROPS
  tooltipText: '',
  tooltipTextColor: 'white--clr',
  tooltipBackgroundColor: 'primary--sbg',

  // ICON LEFT PROPS
  iconLeft: null,
  iconLeftBadgeText: '',
  iconLeftBadgeTextColor: 'white--clr',
  iconLeftBadgeBackgroundColor: '',

  // ICON RIGHT PROPS
  iconRight: null,
  iconRightText: 'white--clr',
  iconRightTextColor: '',
  iconRightBackgroundColor: '',

  // BADGE PROPS
  badgeText: '',
  badgeTextColor: 'white--clr',
  badgeColor: '',

  // FUNCTION PROPS
  onClick: () => { },
  onDoubleClick: () => { },

  // BOOLEAN PROPS
  isCollapsed: false,
  hasBorder: false,
  hasRoundIconRight: false,
};

export default ListBlock;