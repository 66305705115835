import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Asset/';

const getAsset = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAsset`, constants.REQUIRE_INTERCEPTORS);

const updateAsset = async (menuItem) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateAsset`, menuItem, constants.REQUIRE_INTERCEPTORS);

export {
  getAsset,
  updateAsset
};