/* eslint-disable import/extensions */
/* eslint-disable no-shadow */
// DEPENDENCIES
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';
import ModalBlock from './ModalBlock';
// HELPERS
import * as confirmModal from '../redux/confirmModalRedux.js';

const ConfirmModal = (props) => {
  const {
    hideConfirmModal,
    confirmModal,
    setProcessing
  } = props;

  const handleConfirm = () => {
    setProcessing();
    confirmModal.btnAction();
  };

  const handleCloseBtn = () => {
    hideConfirmModal();
    if (confirmModal.closeBtnAction) { confirmModal.closeBtnAction(); }
  };

  const handleEnterKey = (event) => {
    if (event.keyCode === 13 && confirmModal.isVisible) {
      handleConfirm();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleEnterKey);
    return () => {
      window.removeEventListener('keydown', handleEnterKey);
    };
  }, [confirmModal.isVisible]);

  return (
    <ModalBlock
      centered
      size="md"
      isVisible={confirmModal.isVisible}
      onHide={handleCloseBtn}
      hasCloseAction
      closeActionOnClick={handleCloseBtn}
      contentHeader={confirmModal.title}
      padded
      static
      contentDescription={confirmModal.text}
      contentDescriptionClassName="ml-0"
      primaryModalActionText=""
    >
      <CustomBlock className="content-container--actions pt-20 pr-0">
        <Button
          text="Cancel"
          className="grey--bg"
          onClick={handleCloseBtn}
          isDisabled={confirmModal.isProcessing}
          size="xs"
        />

        <Button
          text={confirmModal.rightBtnText}
          className="primary--bg mr-0"
          isLoading={confirmModal.isProcessing}
          isDisabled={confirmModal.isProcessing}
          size="xs"
          // onClick={() => {
          //   setProcessing();
          //   confirmModal.btnAction();
          // }}
          onClick={handleConfirm}
        />
      </CustomBlock>
    </ModalBlock>
  );
};

const mapStateFromProps = (state) => ({ confirmModal: state.confirmModal });

export default connect(mapStateFromProps, confirmModal.actions)(ConfirmModal);