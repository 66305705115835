import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'FooterItem/';

const getAllFooterItems = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllFooterItems`, constants.REQUIRE_INTERCEPTORS);

const getFooterItemById = async (footerItemId) => axiosInstance.get(`${ENDPOINTORIGIN}GetFooterItemById?footerItemId=${footerItemId}`, constants.REQUIRE_INTERCEPTORS);

const createFooterItem = async (footerItem) => axiosInstance.post(`${ENDPOINTORIGIN}CreateFooterItem`, footerItem, constants.REQUIRE_INTERCEPTORS);

const updateFooterItem = async (footerItem) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateFooterItem`, footerItem, constants.REQUIRE_INTERCEPTORS);

const deleteFooterItem = async (footerItemId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteFooterItem?footerItemId=${footerItemId}`, constants.REQUIRE_INTERCEPTORS);

const sortFooterItems = async (sortOrders) => axiosInstance.put(`${ENDPOINTORIGIN}SortFooterItems`, sortOrders, constants.REQUIRE_INTERCEPTORS);

export {
  getAllFooterItems,
  getFooterItemById,
  createFooterItem,
  updateFooterItem,
  deleteFooterItem,
  sortFooterItems
};