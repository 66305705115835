import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'BannerItem/';

const getBannerItemById = async (bannerItemId) => axiosInstance.get(`${ENDPOINTORIGIN}GetBannerItemById?bannerItemId=${bannerItemId}`, constants.REQUIRE_INTERCEPTORS);

const createBannerItem = async (bannerItem) => axiosInstance.post(`${ENDPOINTORIGIN}CreateBannerItem`, bannerItem, constants.REQUIRE_INTERCEPTORS);

const updateBannerItem = async (bannerItem) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateBannerItem`, bannerItem, constants.REQUIRE_INTERCEPTORS);

const deleteBannerItem = async (bannerItemId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteBannerItem?id=${bannerItemId}`, constants.REQUIRE_INTERCEPTORS);

const sortBannerItems = async (bannerItems) => axiosInstance.put(`${ENDPOINTORIGIN}SortBannerItems`, bannerItems, constants.REQUIRE_INTERCEPTORS);

export {
  getBannerItemById,
  createBannerItem,
  updateBannerItem,
  deleteBannerItem,
  sortBannerItems
};