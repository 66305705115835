/* eslint-disable import/extensions */
/* eslint-disable no-shadow */
// DEPENDENCIES
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// ICONS
import { HiOutlineEmojiSad, HiOutlineLightBulb } from 'react-icons/hi';
import { FiThumbsUp } from 'react-icons/fi';
import { BiShocked } from 'react-icons/bi';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
// SERVICES AND HELPERS
import * as alertToast from '../redux/alertToastRedux';

const AlertToast = (props) => {
  const {
    hideAlert,
    alertToast
  } = props;

  useEffect(() => {
    if (alertToast.visible) {
      setTimeout(() => {
        hideAlert();
      }, 3500);
    }
  }, [alertToast.visible, hideAlert]);

  const iconBackgroundColors = {
    success: 'lighter-green--bg',
    error: 'lighter-red--bg',
    warning: 'lighter-yellow--bg',
    info: 'lighter-blue--bg',
  };

  return (
    <CustomBlock className={`magnificent-toast-container ${alertToast.visible ? 'visible' : 'hidden'}`}>
      <CustomBlock className="magnificent-toast">
        <CustomBlock className="toast-body">
          <CustomBlock className="icon-container">
            <CustomBlock className={`icon ${alertToast.state ? iconBackgroundColors[alertToast.state] : 'light-grey--bg'}`}>
              {alertToast.state === 'success' && <FiThumbsUp size={30} className="success--clr" />}
              {alertToast.state === 'error' && <HiOutlineEmojiSad size={35} className="light-red--clr" />}
              {alertToast.state === 'warning' && <BiShocked size={30} className="warning--clr" />}
              {alertToast.state === 'info' && <HiOutlineLightBulb size={35} className="info--clr" />}
            </CustomBlock>
          </CustomBlock>

          <CustomBlock className="text-container">
            <p className="text">
              {alertToast.text}
            </p>
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </CustomBlock>
  );
};

const mapStateFromProps = (state) => ({ alertToast: state.alertToast });

export default connect(mapStateFromProps, alertToast.actions)(AlertToast);