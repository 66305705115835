// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';

const Section = (props) => {
  const {
    hasNoContainer,
    isFullWidth,
    className,
    containerStyle,
    rowClassName,
    rowStyle,
    children
  } = props;

  return (
    <div
      className={`${hasNoContainer ? '' : 'container'}${isFullWidth ? '-fluid' : ''} ${className}`}
      style={containerStyle}
    >
      <div
        className={`row ${rowClassName}`}
        style={rowStyle}
      >
        {children}
      </div>
    </div>
  );
};

Section.propTypes = {
  // MAIN PROPS
  className: PropTypes.string,
  rowClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  rowStyle: PropTypes.object,
  children: PropTypes.any,

  // BOOLEAN PROPS
  hasNoContainer: PropTypes.bool,
  isFullWidth: PropTypes.bool,
};

Section.defaultProps = {
  // SIZE AND APPEARANCE PROPS
  className: '',
  rowClassName: '',
  containerStyle: {},
  rowStyle: {},
  children: null,

  // BOOLEAN PROPS
  hasNoContainer: false,
  isFullWidth: false,
};

export default Section;