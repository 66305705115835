/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Fade } from 'react-awesome-reveal';
// ICONS
import {
  MdEmail,
  MdLocalPhone,
  MdLocationPin,
} from 'react-icons/md';
// COMPONENTS
import Slider from 'react-slick';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import ContactForm from '../../../components/ContactForm';
import Image from '../../../components/Image';
import TabbedInterface from '../../../components/TabbedInterface';
// ASSETS
import CompanyLogo from '../../../assets/img/logo_default-light-bg.svg';
import BannerImage from '../../../assets/img/banner-image_default.png';
// SERVICES AND HELPERS
import * as auth from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';
import * as settingService from '../../../services/management/settingService';
import constants from '../../../constants/constants';

const RotatingImageSlider = (props) => {
  const { containerClassName } = props;
  const [imageSliderLength, setImageSliderLength] = useState(0);
  const rotatingImages = Array.from({ length: imageSliderLength }, (_, i) => `${constants.API_URL}Attachments/RotatingImages/image-${i + 1}.png`);

  useEffect(() => {
    if (!imageSliderLength) {
      settingService.getSettingByKey('ContactPage_ImageSliderLength').then((res) => {
        setImageSliderLength(res);
      });
    }
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // customPaging: customPagingElement,
    dotsClass: 'slick-dots custom-dots'
  };

  return (
    <Slider
      {...settings}
      className={`${containerClassName}`}
    >
      {rotatingImages.map((imageUrl, index) => (
        <img
          src={imageUrl}
          alt={`Slide ${index}`}
          className="slick-image"
        />
      ))}
    </Slider>
  );
};

const ContactPage = (props) => {
  const { showAlert, history, cmsInfo } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [googleMapURL, setGoogleMapURL] = useState([]);
  // const formConfig = [
  //   { name: 'firstName', label: 'First Name', required: true, placeholder: 'e.g. John', max: 100 },
  //   { name: 'lastName', label: 'Last Name', required: true, placeholder: 'e.g. Smith', max: 100 },
  //   { name: 'email', label: 'Email Address', required: true, placeholder: 'e.g. johnsmith@example.com', max: 100, email: true },
  //   { name: 'phoneNumber', label: 'Phone Number', placeholder: 'e.g. (555) 555-5555', max: 50 },
  //   { name: 'message', label: 'Message', required: true, placeholder: 'Provide your message here...', textarea: true, cols: 12 },
  // ];

  useEffect(() => {
    fetchGoogleMapURL('GoogleMapURL');
  }, []);

  const fetchGoogleMapURL = (key) => {
    setIsLoading(true);
    settingService.getSettingByKey(key).then((res) => {
      setGoogleMapURL(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  // const submitForm = async (values) => {
  //   setIsLoading(true);
  //   await emailService.sendEmail(values).then(() => {
  //     showAlert({ text: 'Success! Your message has been received.', state: 'success' });
  //     history.push('/contact-livingston-parish-drainage-district-2');
  //   }).catch((ex) => {
  //     showAlert({ text: ex.message, state: 'error' });
  //   }).finally(() => {
  //     setIsLoading(false);
  //   });
  // };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Contact"
        imageSource={BannerImage}
      />

      {/* COMPANY LOGO & IMAGE SECTION */}
      <CustomBlock className="main-content">
        <CustomBlock className="content-section logo-section pb-20">
          <Image
            containerClassName="logo-container"
            className="logo"
            source={CompanyLogo}
          />
          <RotatingImageSlider
            containerClassName="image-container"
          />
        </CustomBlock>

        <CustomBlock className="content-section flex-container">
          {/* COMPANY DETAILS SECTION */}
          <CustomBlock className="details-section">
            <Fade direction="up">
              <h2 className="size-lg primary--clr fw-700 mb-10">Contact</h2>
            </Fade>
            <CustomBlock className="contact">
              <CustomBlock className="contact__row">
                <CustomBlock className="contact__row__icon">
                  <MdEmail size="18" className="light--clr" />
                </CustomBlock>
                <p className="size-md mid-grey--clr">{cmsInfo.emailAddress}</p>
              </CustomBlock>

              <CustomBlock className="contact__row">
                <CustomBlock className="contact__row__icon">
                  <MdLocalPhone size="18" className="light--clr" />
                </CustomBlock>
                <p className="size-md mid-grey--clr">{cmsInfo.phoneNumber}</p>
              </CustomBlock>
            </CustomBlock>

            <CustomBlock className="location mt-30">
              <Fade direction="up" delay={300}>
                <h2 className="size-lg primary--clr fw-700 mb-10">Location</h2>
              </Fade>
              <CustomBlock className="contact__row">
                <CustomBlock className="contact__row__icon">
                  <MdLocationPin size="18" className="light--clr" />
                </CustomBlock>
                <p className="size-md mid-grey--clr">
                  {cmsInfo.streetAddress1}, {cmsInfo.streetAddress2}<br />
                  {cmsInfo.city}, {cmsInfo.state} {cmsInfo.zipCode}
                </p>
              </CustomBlock>
            </CustomBlock>

            {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours || cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
              <CustomBlock className="hours mt-30">
                <Fade direction="up" delay={600}>
                  <h2 className="size-lg primary--clr fw-700 mb-10">Hours of Operation</h2>
                </Fade>
                <p className="size-md mid-grey--clr">
                  {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours) && (
                    <CustomBlock className="">
                      {cmsInfo.primaryWeekDayHours && `${cmsInfo.primaryWeekDayHours}`}
                      <br />
                      {cmsInfo.secondaryWeekDayHours && `${cmsInfo.secondaryWeekDayHours}`}
                    </CustomBlock>
                  )}

                </p>
                {(cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
                  <CustomBlock>
                    {cmsInfo.primaryWeekEndHours && (
                      <p>{cmsInfo.primaryWeekEndHours}</p>
                    )}
                    {cmsInfo.secondaryWeekEndHours && (
                      <p>{cmsInfo.secondaryWeekEndHours}</p>
                    )}
                  </CustomBlock>
                )}
              </CustomBlock>
            )}
          </CustomBlock>

          {/* GOOGLE MAPS SECTION */}
          {googleMapURL.length > 0 && (
            <CustomBlock className="google-map-section">
              <iframe
                title="Company Google Map"
                frameBorder="0"
                style={{ border: '0 !important' }}
                src={googleMapURL}
                allowFullScreen
              />
            </CustomBlock>
          )}
        </CustomBlock>

        {/* CONTENT SECTION - CONTACT TABS */}
        <CustomBlock className="content-section">
          <CustomBlock className="contact-tabs-section">
            <TabbedInterface />
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alertToast.actions
})(ContactPage);