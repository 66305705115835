// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// COMPONENTS
import Modal from 'react-bootstrap/Modal';
// ICONS
import { IoClose } from 'react-icons/io5';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import ContentHeader from './ContentHeader';
import Button from './Button';
// HELPERS

const ModalBlock = (props) => {
  const {
    closeIconColor,
    contentHeader,
    subtitle,
    primaryButtonText,
    contentDescription,
    children,
    buttonSize,
    buttonActionsContainerClassName,
    primaryModalActionType,
    primaryModalActionText,
    primaryModalActionColor,
    secondaryModalActionType,
    secondaryModalActionText,
    secondaryModalActionColor,
    onHide,
    closeActionOnClick,
    primaryModalActionOnClick,
    secondaryModalActionOnClick,
    isVisible,
    isCentered,
    hasCloseAction,
    isTitleCentered,
    isPrimaryActionHidden,
    isSecondaryActionHidden,
    isActionsCentered,
    modalClassName
  } = props;

  return (
    <Modal
      {...props}
      show={isVisible}
      onHide={onHide}
      centered={isCentered}
      className={modalClassName}
    >
      {
        hasCloseAction
        && (
          <CustomBlock className="close-action-container">
            <button
              className="close-action"
              onClick={onHide || closeActionOnClick}
            >
              <IoClose
                size={35}
                className={`${closeIconColor || 'primary--clr'}`}
              />
            </button>
          </CustomBlock>
        )
      }

      <CustomBlock className="content-container--card-style content">
        <ContentHeader
          {...props}
          title={contentHeader}
          subtitle={subtitle}
          primaryButtonText={primaryButtonText}
          headerSize="mdlg"
          className="text-left"
        />

        {
          contentDescription
          && (
            <CustomBlock className={`${isTitleCentered ? 'text-center' : ''}`}>
              <p className="content-description">
                {contentDescription}
              </p>
            </CustomBlock>
          )
        }

        {children}

        {
          primaryModalActionText
          && (
            <CustomBlock className={`${buttonActionsContainerClassName} content-container--actions ${isActionsCentered ? 'flex-center' : ''}`}>
              {
                !isSecondaryActionHidden
                && (
                  <Button
                    type={secondaryModalActionType}
                    text={secondaryModalActionText}
                    className={secondaryModalActionColor}
                    onClick={secondaryModalActionOnClick || onHide}
                    size={buttonSize}
                  />
                )
              }

              {
                !isPrimaryActionHidden
                && (
                  <Button
                    type={primaryModalActionType}
                    text={primaryModalActionText}
                    className={primaryModalActionColor}
                    onClick={primaryModalActionOnClick}
                    size={buttonSize}
                  />
                )
              }
            </CustomBlock>
          )
        }
      </CustomBlock>
    </Modal>
  );
};

ModalBlock.propTypes = {
  // HEADER PROPS
  contentHeader: PropTypes.string,
  subtitle: PropTypes.string,
  primaryButtonText: PropTypes.string,

  // CLOSE ICON PROPS
  closeIconColor: PropTypes.string,

  // CONTENT PROPS
  contentDescription: PropTypes.any,
  children: PropTypes.any,

  // ACTION PROPS
  primaryModalActionText: PropTypes.string,
  buttonActionsContainerClassName: PropTypes.string,
  primaryModalActionType: PropTypes.string,
  primaryModalActionColor: PropTypes.string,
  secondaryModalActionType: PropTypes.string,
  secondaryModalActionText: PropTypes.string,
  secondaryModalActionColor: PropTypes.string,
  buttonSize: PropTypes.string,

  // FUNCTION PROPS
  onHide: PropTypes.func,
  closeActionOnClick: PropTypes.func,
  primaryModalActionOnClick: PropTypes.func,
  secondaryModalActionOnClick: PropTypes.func,

  // BOOLEAN PROPS
  isVisible: PropTypes.bool,
  isCentered: PropTypes.bool,
  hasCloseAction: PropTypes.bool,
  isTitleCentered: PropTypes.bool,
  isPrimaryActionHidden: PropTypes.bool,
  isSecondaryActionHidden: PropTypes.bool,
  isActionsCentered: PropTypes.bool,
};

ModalBlock.defaultProps = {
  // HEADER PROPS
  contentHeader: '',
  subtitle: '',
  primaryButtonText: '',

  // CLOSE ICON PROPS
  closeIconColor: '',

  // CONTENT PROPS
  contentDescription: null,
  children: null,

  // ACTION PROPS
  buttonActionsContainerClassName: '',
  primaryModalActionType: '',
  primaryModalActionText: 'Delete',
  primaryModalActionColor: 'primary--bg',
  secondaryModalActionType: '',
  secondaryModalActionText: 'Cancel',
  secondaryModalActionColor: 'danger--bg',
  buttonSize: 'xs',

  // FUNCTION PROPS
  onHide: () => { },
  closeActionOnClick: () => { },
  primaryModalActionOnClick: () => { },
  secondaryModalActionOnClick: null,

  // BOOLEAN PROPS
  isVisible: false,
  isCentered: true,
  hasCloseAction: false,
  isTitleCentered: false,
  isPrimaryActionHidden: false,
  isSecondaryActionHidden: false,
  isActionsCentered: false,
};

export default ModalBlock;