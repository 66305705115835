/* eslint-disable react/self-closing-comp */
// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';

const Chip = (props) => {
  const {
    label,
    labelColor,
    backgroundColor,
    onClick,
    hasBorder
  } = props;

  return (
    <CustomBlock className="chip-container">
      <Button
        text={label}
        onClick={onClick}
        className={`chip ${hasBorder ? 'bordered' : ''} ${labelColor} ${backgroundColor}`}
      />
    </CustomBlock>
  );
};

Chip.propTypes = {
  // MAIN PROPS
  label: PropTypes.string.isRequired,
  labelColor: PropTypes.string,
  backgroundColor: PropTypes.string,

  // FUNCTION PROPS
  onClick: PropTypes.func,

  // BOOLEAN PROPS
  hasBorder: PropTypes.bool
};

Chip.defaultProps = {
  // MAIN PROPS
  labelColor: 'info--clr',
  backgroundColor: 'lighter-blue--sbg',

  // FUNCTION PROPS
  onClick: null,

  // BOOLEAN PROPS
  hasBorder: false
};

export default Chip;