/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable object-curly-newline */
/* eslint-disable no-return-assign */
// DEPENDENCIES
import React from 'react';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import { Container, Row, Col } from 'react-bootstrap';
import CustomBlock from '../../../components/CustomBlock';
import Button from '../../../components/Button';
import Accordion from '../../../components/Accordion';
import IllustrationBackgroundPattern1 from '../../../assets/img/illustration_texture-pattern-1.png';

const FrequentlyAskedQuestions = () => {
  const lineItemStyles = {
    width: '20%',
    minWidth: '200px',
    lineHeight: '1.4',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    background: 'linear-gradient(45deg, rgb(171 214 255 / 85%) 0%, rgb(232 244 255 / 85%) 100%)',
    fontWeight: '600',
    fontSize: '1em',
  };

  const data = [
    {
      id: 1,
      header: 'What areas does LPGDD2 serve?',
      body: (
        <Container fluid>
          <p className="size-sm lh-base">Starting at the intersection of the Livingston Parish/St. Helena Parish line and the centerline of Middle Colyell Creek:</p>
          <Row className="area-list d-flex flex-wrap justify-content-between gap-2 p-0 size-xxxs">
            <Col className="p-2 round dark-grey--clr" style={lineItemStyles}>South along the centerline of Middle Colyell Creek to its intersection with Louisiana Highway 1024 (Cane Market Road)</Col>
            <Col className="p-2 round dark-grey--clr" style={lineItemStyles}>West along the centerline of Louisiana Highway 1024 to its intersection with West Colyell Creek</Col>
            <Col className="p-2 round dark-grey--clr" style={lineItemStyles}>East along the Livingston Parish/St. Helena Parish line to the starting point</Col>
            <Col className="p-2 round dark-grey--clr" style={lineItemStyles}>Westerly for approximately 8,300 feet to the west line of Gravity Drainage District No. 5</Col>
            <Col className="p-2 round dark-grey--clr" style={lineItemStyles}>South approximately 2,600 feet to the centerline of Louisiana Highway 1025 (Arnold Road)</Col>
            <Col className="p-2 round dark-grey--clr" style={lineItemStyles}>West along the centerline of Louisiana Highway 1025 to its intersection with Louisiana Highway 16</Col>
            <Col className="p-2 round dark-grey--clr" style={lineItemStyles}>North along the centerline of Louisiana Highway 16 to the first intersection with Amite Church Road</Col>
            <Col className="p-2 round dark-grey--clr" style={lineItemStyles}>West along the centerline of Amite Church Road to its intersection with Louisiana Highway 1019</Col>
            <Col className="p-2 round dark-grey--clr" style={lineItemStyles}>South along the centerline of Louisiana Highway 1019 to its intersection with Louisiana Highway 64</Col>
            <Col className="p-2 round dark-grey--clr" style={lineItemStyles}>West along the centerline of Louisiana Highway 64 to its intersection with the centerline of the Amite River</Col>
            <Col className="p-2 round dark-grey--clr" style={lineItemStyles}>Northeast along the centerline of the Amite River back to the Livingston Parish/St. Helena Parish line</Col>
            <Col className="p-2 round dark-grey--clr" style={lineItemStyles}>South along the centerline of West Colyell Creek for approximately 6,000 feet to the north line of Gravity Drainage District No. 5 and the north line of Section 11, T6S-R3E</Col>
          </Row>
        </Container>
      )
    },
    {
      id: 2,
      header: 'How can I contact LPGDD2?',
      body: (
        <Container fluid className="py-30">
          <p>You can reach us by mail at 8639 Springfield Rd, PO Box 617, Watson, LA 70786, by phone at (225) 791-6509, or via email at lpgdd2@outlook.com. Our office hours are Monday to Thursday, 6AM to 4:30PM.</p>
          <p>LPGDD2 includes Livingston Parish Council Districts 1, 2, and 3. To contact your councilman <a href="https://www.livingstonparishcouncil.com/council/page/council-districts" className="primary-lightest--clr" target="_blank" rel="noreferrer">click here</a>.</p>
        </Container>
      )
    },
    {
      id: 3,
      header: 'What services does LPGDD2 provide?',
      body: (
        <Container fluid className="py-30">
          <p>We are responsible for the maintenance of drainage infrastructure within our boundaries, including the removal of brush and obstructions from canals, and controlling growth by spraying chemicals in channels, as approved by the State of Louisiana.</p>
        </Container>
      )
    },
    {
      id: 4,
      header: 'How can I report a drainage problem?',
      body: (
        <Container fluid className="py-30">
          <p>Residents can report drainage issues directly through our contact information provided on the website. Please provide as much detail as possible to help us address the problem efficiently.</p>
        </Container>
      )
    }
  ];

  return (
    <CustomBlock
      className="decoration-wrapper"
      style={{
        backgroundImage: `linear-gradient(45deg, #135f93 40%, #038503 100%), url(${IllustrationBackgroundPattern1})`,
        backgroundBlendMode: 'multiply',
        backgroundPosition: 'center',
        borderRadius: '0',
      }}
    >
      <CustomBlock className="content-section faqs-section pt-60 pb-60">
        <CustomBlock className="faqs">
          {/* CONTAINER 1 */}
          <CustomBlock className="container-1">
            <h3 className="header size-xl fw-700 light--clr pb-20">Frequently Asked Questions</h3>
            <p className="description lighter--clr size-mdlg">
              Our team at Gravity Drainage District 2 is committed to more than just maintenance.
            </p>
            <Fade direction="left">
              <Button
                hasRoundCorners
                type="button"
                text="Questions? Get In Touch"
                to="/contact-livingston-parish-drainage-district-2"
                className="secondary--sbg mt-40"
              />
            </Fade>
          </CustomBlock>

          {/* CONTAINER 2 */}
          <CustomBlock className="container-2">
            <Accordion
              headerClasses="size-mdlg fw-600 light--clr"
              bodyClasses="size-sm light--clr"
              data={data}
            />
            <div className="scroll-container">
              <Row className="area-list d-flex flex-wrap justify-content-between gap-2 p-0 size-xxxs">
                {/* COLUMNS */}
              </Row>
            </div>
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </CustomBlock>
  );
};

export default FrequentlyAskedQuestions;