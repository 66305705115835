/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import moment from 'moment';
// ICONS
import {
  FaFilePdf,
  FaCalendar,
  FaClock,
  FaLocationDot
} from 'react-icons/fa6';
import { IoFilter } from 'react-icons/io5';
// COMPONENTS
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import Button from '../../../components/Button';
// ASSETS
import BannerImage from '../../../assets/img/banner-image_default.png';
// HELPERS & SERVICES
import * as meetingService from '../../../services/management/meetingService';
import constants from '../../../constants/constants';
import ContentSection from '../../../components/ContentSection';

const MeetingsPage = (props) => {
  const { history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [meetingsData, setMeetingsData] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');

  useEffect(() => {
    fetchMeetings();
  }, []);

  useEffect(() => {
    const filteredMeetings = getMeetingsByFilterType(selectedFilter);
    setMeetings(filteredMeetings);
  }, [selectedFilter, meetingsData]);

  const fetchMeetings = () => {
    setIsLoading(true);
    meetingService.getAllActiveMeetings().then((res) => {
      setMeetingsData(res);
      const allMeetings = getMeetingsByFilterType(selectedFilter);
      setMeetings(allMeetings);
    }).catch(() => {
      // console.log('', ex.message);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getMeetingsByFilterType = (filterType) => {
    let filteredMeetings = [];
    const currentDate = moment().startOf('day');

    switch (filterType) {
      case 'previous':
        filteredMeetings = meetingsData
          .filter((meeting) => moment(meeting.date).isBefore(currentDate))
          .sort((a, b) => moment(b.date).diff(a.date));
        break;

      case 'upcoming':
        filteredMeetings = meetingsData
          .filter((meeting) => moment(meeting.date).isSameOrAfter(currentDate))
          .sort((a, b) => moment(a.date).diff(b.date));
        break;

      case 'all':
      default:
        filteredMeetings = [...meetingsData].sort((b, a) => moment(b.date).diff(a.date));
        break;
    }

    return filteredMeetings;
  };

  const handleFilterChange = (filterType) => {
    setSelectedFilter(filterType);

    const filteredMeetings = getMeetingsByFilterType(filterType);
    setMeetings(filteredMeetings);
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Board Meetings"
        imageSource={BannerImage}
      />

      <CustomBlock className="main-content">
        {/* INTRO SECTION */}
        <ContentSection
          isTextOnly
          hasRoundCorners
          title="Board Meetings and Archives"
          description="Access agendas, minutes, and schedules for past and upcoming board meetings. Stay updated with district decisions and forthcoming discussions, all in one convenient location."
          descriptionColor="mid-grey--clr"
          primaryActionText="Questions? Let us know"
          primaryActionOnClick={() => history.push('/contact-livingston-parish-drainage-district-2')}
          primaryActionColor="secondary--sbg"
        />

        {/* LISTING SECTION */}
        <CustomBlock className="content-section meetings-listing row-gap-90">

          <CustomBlock className="page-actions-container flex-start-col w-100">
            {/* PAGE ACTIONS */}
            <CustomBlock className="filters-header flex-start gap-5 mb-10">
              <IoFilter size="24" className="primary--clr mr-5" />
              <p className="size-mdlg fw-600 primary--clr">Filter Meetings</p>
            </CustomBlock>

            <CustomBlock className="page-actions flex-start wrap">
              <Button
                className={`action mid-grey--clr size-xs fw-400 ${selectedFilter === 'all' ? 'selected' : ''}`}
                text="All Meetings"
                onClick={() => handleFilterChange('all')}
              />
              <Button
                className={`action mid-grey--clr size-xs fw-400 ${selectedFilter === 'previous' ? 'selected' : ''}`}
                text="Previous"
                onClick={() => handleFilterChange('previous')}
              />
              <Button
                className={`action mid-grey--clr size-xs fw-400 ${selectedFilter === 'upcoming' ? 'selected' : ''}`}
                text="Upcoming"
                onClick={() => handleFilterChange('upcoming')}
              />
            </CustomBlock>
          </CustomBlock>

          {/* MEETINGS LISTING */}
          {meetings && meetings.map((meeting) => {
            const isPastMeeting = moment(meeting.date).isBefore(moment().startOf('day'));

            return (
              <CustomBlock key={meeting.id} className={`meeting row-gap-20 col-gap-30 ${selectedFilter === 'all' && isPastMeeting ? 'past' : ''}`}>
                {/* DATE HEADER */}
                <CustomBlock className="meeting__date mt-10">
                  <p className="month light--clr fw-500">{moment(meeting.date).format('MMMM').toUpperCase()}</p>
                  <p className="day mid-grey--clr fw-300">{moment(meeting.date).format('Do')}</p>
                </CustomBlock>

                <CustomBlock className="meeting__content">
                  {/* HEADER */}
                  <p className="size-lg fw-600 primary--clr">Board Meeting</p>

                  <CustomBlock className="meeting__content__details flex-start wrap row-gap-10 col-gap-20">
                    {/* DATE */}
                    <CustomBlock className="flex-start gap-5">
                      <FaCalendar size={18} className="light-grey--clr" />
                      <p className="size-xs mid-grey--clr">{moment(meeting.date).format('MMMM D, yyyy')}</p>
                    </CustomBlock>

                    {/* TIME */}
                    {(meeting.startTime || meeting.endTime) && (
                      <CustomBlock className="flex-start gap-5">
                        <FaClock size={18} className="light-grey--clr" />
                        {meeting.startTime && (
                          <p className="size-xs mid-grey--clr">{moment(meeting.startTime, 'HH:mm').format('h:mm A')}</p>
                        )}
                        {(meeting.startTime && meeting.endTime) && (
                          <p className="size-xs mid-grey--clr">-</p>
                        )}
                        {meeting.endTime && (
                          <p className="size-xs mid-grey--clr">{moment(meeting.endTime, 'HH:mm').format('h:mm A')}</p>
                        )}
                      </CustomBlock>
                    )}

                    {/* LOCATION */}
                    <CustomBlock className="flex-start gap-5">
                      <FaLocationDot size={18} className="light-grey--clr" />
                      <p className="size-xs mid-grey--clr">{meeting.location}</p>
                    </CustomBlock>
                  </CustomBlock>

                  {/* DESCRIPTION */}
                  <p className="size-md mid-grey--clr">{meeting.description}</p>

                  {/* MEETING ACTIONS CONTAINER */}
                  <CustomBlock className="meeting__content__actions mt-10">
                    {(meeting.minutesFilePath || meeting.agendasFilePath) && (
                      <CustomBlock className="flex-start gap-20">
                        {meeting.agendasFilePath && (
                          <Button
                            text="Agenda"
                            title={`${moment(meeting.date).format('MMMM D, yyyy')}`}
                            className="action primary-lighter--sbg light--clr fw-400"
                            iconLeft={<FaFilePdf size={20} className="light--clr" />}
                            onClick={() => window.open(`${constants.API_URL}Attachments/${meeting.agendasFilePath}`)}
                          />
                        )}

                        {meeting.minutesFilePath && (
                          <Button
                            text="Minutes"
                            title={`${moment(meeting.date).format('MMMM D, yyyy')}`}
                            className="action secondary-lighter--sbg light--clr fw-400"
                            iconLeft={<FaFilePdf size={20} className="light--clr" />}
                            onClick={() => window.open(`${constants.API_URL}Attachments/${meeting.minutesFilePath}`)}
                          />
                        )}
                      </CustomBlock>
                    )}
                  </CustomBlock>
                </CustomBlock>
              </CustomBlock>
            );
          })}
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default MeetingsPage;