/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const CheckboxBlock = (props) => {
  const {
    id,
    containerClassName,
    labelClassName,
    label,
    isChecked,
    onChange
  } = props;
  return (
    <CustomBlock className={`checkbox-block-container ${containerClassName}`}>
      <input
        {...props}
        type="checkbox"
        className="checkbox-input"
        checked={isChecked}
        onChange={onChange}
      />

      <label
        {...props}
        className={`checkbox-label ${labelClassName}`}
        for={id}
      >
        {label}
      </label>
    </CustomBlock>
  );
};

CheckboxBlock.propTypes = {
  // MAIN PROPS
  id: PropTypes.string,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func
};

CheckboxBlock.defaultProps = {
  // MAIN PROPS
  id: '',
  label: '',
  containerClassName: '',
  labelClassName: '',
  isChecked: false,
  onChange: () => { }
};

export default CheckboxBlock;