/* eslint-disable react/self-closing-comp */
// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import TextBlock from './TextBlock';

const ContentStrip = (props) => {
  const {
    badgeBackgroundColor,
    badgeText,
    imageSource,
    title,
    leftBlockText,
    rightBlockText,
    onClick,
    isFullWidth
  } = props;

  return (
    <button
      className={`content-strip ${isFullWidth ? 'full-width' : ''}`}
      onClick={onClick}
    >
      <CustomBlock className="image-block">
        <CustomBlock
          className="content-image"
          style={{ backgroundImage: `url(${imageSource})` }}
        />
        {
          badgeText !== ''
          && (
            <CustomBlock className={`badge ${badgeBackgroundColor}`}>
              <p>{badgeText}</p>
            </CustomBlock>
          )
        }
      </CustomBlock>

      <CustomBlock className="details-block">

        <CustomBlock className="subtitle-block">
          <CustomBlock className="text left-block">
            <p>{leftBlockText}</p>
          </CustomBlock>

          <CustomBlock className="text right-block">
            <p>{rightBlockText}</p>
          </CustomBlock>
        </CustomBlock>

        <CustomBlock className="content-details-block">
          <TextBlock
            type="description"
            text={title}
            position="left"
          />
        </CustomBlock>
      </CustomBlock>
    </button>
  );
};

ContentStrip.propTypes = {
  // MAIN PROPS
  imageSource: PropTypes.number,
  title: PropTypes.string,
  leftBlockText: PropTypes.string,
  rightBlockText: PropTypes.string,

  // BADGE PROPS
  badgeText: PropTypes.string,
  badgeBackgroundColor: PropTypes.string,

  // FUNCTION PROPS
  onClick: PropTypes.func,

  // BOOLEAN PROPS
  isFullWidth: PropTypes.bool
};

ContentStrip.defaultProps = {
  // MAIN PROPS
  imageSource: null,
  title: '',
  leftBlockText: '',
  rightBlockText: '',

  // BADGE PROPS
  badgeText: '',
  badgeBackgroundColor: 'danger--sbg',

  // FUNCTION PROPS
  onClick: null,

  // BOOLEAN PROPS
  isFullWidth: false
};

export default ContentStrip;