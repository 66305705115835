// DEPENDENCIES
import React from 'react';
// COMPONENTS
// ICONS
import { HiOutlineMail } from 'react-icons/hi';
// CUSTOM COMPONENTS
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import Button from '../../../../components/Button';

const ForgotPasswordBlock = (props) => {
  const { returnActionOnClick } = props;
  const iconSize = 22;

  return (
    <FormBlock>
      <ContentHeader
        title="Recover Account"
        headerSize="xl"
      />

      <InputBlock
        label="Email Address"
        placeholder="Enter your email address"
        iconLeft={(
          <HiOutlineMail
            size={iconSize}
            className="dark-grey--clr"
          />
        )}
      />
      <CustomBlock className="button-container">
        <Button
          text="Submit"
          className="primary--bg"
        />
      </CustomBlock>

      <CustomBlock className="register-block">
        <button
          onClick={returnActionOnClick}
          className="primary--clr fw-600"
        >
          Back to Login
        </button>
      </CustomBlock>
    </FormBlock>
  );
};

export default ForgotPasswordBlock;