/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
// CUSTOM COMPONENTS
import Overlay from '../../../../components/Overlay';
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import ModalBlock from '../../../../components/ModalBlock';
import Button from '../../../../components/Button';
// SERVICES AND HELPERS
import * as contentSectionService from '../../../../services/cms/contentSectionService';
// REDUX
import constants from '../../../../constants/constants';
import * as helper from '../../../../helpers/helper';
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
import * as bannerService from '../../../../services/cms/bannerService';

const ContentSectionListingPage = (props) => {
  const { showAlert, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [contentSections, setContentSections] = useState([]);
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    getContentSections();
  }, []);

  const getContentSections = () => {
    setIsLoading(true);
    contentSectionService.getAllContentSections('').then((res) => {
      setContentSections(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteContentSection = (contentSectionId) => {
    setIsLoading(true);

    contentSectionService.deleteContentSection(contentSectionId).then((res) => {
      getContentSections();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const deleteBanner = (bannerId) => {
    setIsLoading(true);

    bannerService.deleteBanner(bannerId).then((res) => {
      getContentSections();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,
    jumpToPage: true,
    textLabels: { pagination: { jumpToPage: 'Page No:' } }
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg ml-0"
              size="xxs"
              to={`/cms/management/${contentSections[dataIndex].type === constants.CONTENT_SECTION.ContentSectionDynamicBanner ? 'banner' : 'content-section'}/edit/${contentSections[dataIndex].id}`}
            />

            <Button
              text="Remove"
              className="danger--bg"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove content section',
                  text: 'Are you sure you want to remove this content section?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    if (contentSections[dataIndex].type === constants.CONTENT_SECTION.ContentSectionDynamicBanner) {
                      deleteBanner(contentSections[dataIndex].id);
                    } else {
                      deleteContentSection(contentSections[dataIndex].id);
                    }
                  }
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: columnOptions,
    },
    {
      name: 'title',
      label: 'Title',
      options: columnOptions
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => (
          <p>{helper.mapContentSectionName(contentSections[dataIndex].type)}</p>
        )
      }
    },
    {
      name: 'isActive',
      label: 'Is Active?',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => (
          <p>{contentSections[dataIndex].isActive ? 'Yes' : 'No'}</p>
        )
      }
    },
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Content Sections"
                headerSize="lg"
                primaryButtonText="New Content Section"
                primaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
                primaryButtonTo="/cms/management/content-section"
                secondaryButtonText="New Banner"
                secondaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
                secondaryButtonTo="/cms/management/banner"
              />

              <CustomBlock>
                <MUIDataTable
                  data={contentSections}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* CONFIRM DELETE MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isConfirmDeleteModalVisible}
        size="md"
        contentHeader="Remove Section"
        contentDescription="Are you sure you want to delete this content section?"
        primaryModalActionText="Remove"
        primaryModalActionColor="danger--bg"
        primaryModalActionOnClick={() => { }}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="grey--bg"
        onHide={() => {
          setIsConfirmDeleteModalVisible(false);
        }}
      />
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });
export default connect(mapStateFromProps, { ...alert.actions, ...confirmModal.actions })(ContentSectionListingPage);