import * as Yup from 'yup';
import constants from '../../../../constants/constants';

export default Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required('Content Section Name is required').max(150),
  type: Yup.string().required(),
  // title: Yup.string().required('Title is required').max(1000),
  title: Yup.string().when('type', {
    is: (type) => type === constants.CONTENT_SECTION.ContentSectionCustom,
    then: Yup.string().nullable(),
    otherwise: Yup.string().required('Title is required').max(1000)
  }),
  rawHTML: Yup.string().nullable(),
  description: Yup.string().when('type', {
    is: (type) => ![
      constants.CONTENT_SECTION.ContentSectionTypeBanner,
      constants.CONTENT_SECTION.ContentSectionCustom
    ].includes(type),
    then: Yup.string().nullable().required('Content Details is required'),
    otherwise: Yup.string().nullable()
  }),
  alignment: Yup.string().required(),
  imagePath: Yup.string().nullable(),
  isPadded: Yup.bool(),
  isFloatingCard: Yup.bool(),
  hasRoundedCorners: Yup.bool(),
  isTextOnly: Yup.bool(),
  primaryButtonText: Yup.string().nullable().max(50),
  primaryButtonActionType: Yup.string().nullable().when('primaryButtonText', {
    is: (primaryButtonText) => !primaryButtonText || primaryButtonText.length === 0,
    then: Yup.string().nullable().max(500),
    otherwise: Yup.string().nullable().max(500).required('Primary Action Type is required')
  }),
  primaryButtonLink: Yup.string().nullable().max(500).when('primaryButtonText', {
    is: (primaryButtonText) => !primaryButtonText || primaryButtonText.length === 0,
    then: Yup.string().nullable().max(500),
    otherwise: Yup.string().nullable().max(500).required('Primary Action Link is required')
  }),
  secondaryButtonText: Yup.string().nullable().max(50),
  secondaryButtonActionType: Yup.string().nullable().when('secondaryButtonText', {
    is: (secondaryButtonText) => !secondaryButtonText || secondaryButtonText.length === 0,
    then: Yup.string().nullable().max(500),
    otherwise: Yup.string().nullable().max(500).required('Secondary Action Type is required')
  }),
  secondaryButtonLink: Yup.string().nullable().max(500).when('secondaryButtonText', {
    is: (secondaryButtonText) => !secondaryButtonText || secondaryButtonText.length === 0,
    then: Yup.string().nullable().max(500),
    otherwise: Yup.string().nullable().max(500).required('Secondary Action Link is required')
  }),
}, [['title', 'description', 'type', 'primaryButtonText', 'secondaryButtonActionText']]);