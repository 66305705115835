/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
// import Image from './Image';
import TextBlock from './TextBlock';
import Button from './Button';

const ContentSection = (props) => {
  const {
    backgroundColor,
    title,
    titleColor,
    description,
    descriptionColor,
    cardBackgroundColor,
    imageSource,
    primaryActionText,
    primaryActionColor,
    primaryActionTo,
    primaryActionOnClick,
    secondaryActionText,
    secondaryActionColor,
    secondaryActionTo,
    secondaryActionOnClick,
    isPrimaryActionTargetBlank,
    isSecondaryActionTargetBlank,
    isReversed,
    isCentered,
    isTitleUppercase,
    hasRoundCorners,
    hasCardBackgroundImage,
    isTextOnly,
    isFloatingCard,
    isFullWidth,
    isFullWidthUnpadded,
  } = props;

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  return (
    <CustomBlock className={`content-section 
    ${isFullWidth ? 'full-width' : ''} 
    ${isFullWidthUnpadded ? 'full-width unpadded' : ''} 
    ${isReversed ? 'reverse-content' : ''} 
    ${backgroundColor}
    `}
    >
      {
        (!isTextOnly && !isFloatingCard)
        && (
          <CustomBlock className="image-block">
            <CustomBlock
              className={`content-image ${hasRoundCorners ? 'round-corners' : ''}`}
              style={{ backgroundImage: `url(${imageSource})` }}
            />
            {/* <Image
              source={imageSource}
              hasRoundCorners={hasRoundCorners}
            /> */}
          </CustomBlock>
        )
      }

      <CustomBlock
        className={`details-block 
        ${isTextOnly ? 'text-only' : ''} 
        ${isFloatingCard ? (hasRoundCorners ? 'text-only floating-card round-corners' : 'text-only floating-card') : ''} 
        ${isFloatingCard ? cardBackgroundColor : ''} 
        ${hasCardBackgroundImage ? 'card-background-image' : ''}
        `}
        style={{ backgroundImage: (hasCardBackgroundImage && cardBackgroundColor === '') ? `url(${imageSource})` : '' }}
      >
        <TextBlock
          isPadded
          isUppercase={isTitleUppercase}
          type="header"
          text={title}
          color={titleColor}
          position={isReversed ? 'right' : (isCentered ? 'center' : 'left')}
        />

        <TextBlock
          type="description"
          text={description}
          color={descriptionColor}
          position={isReversed ? 'right' : (isCentered ? 'center' : 'left')}
        />

        {(primaryActionText !== '' && primaryActionText !== null) || (secondaryActionText !== '' && secondaryActionText !== null) ? (
          <CustomBlock className={`actions ${isCentered ? 'centered' : ''}`}>
            {primaryActionText !== '' && primaryActionText !== null && (
              <Button
                text={primaryActionText}
                className={`${primaryActionColor} ${hasRoundCorners ? 'round-corners' : ''}`}
                to={!isPrimaryActionTargetBlank ? primaryActionTo : ''}
                onClick={isPrimaryActionTargetBlank ? () => openInNewTab(primaryActionTo) : primaryActionOnClick}
              />
            )}

            {secondaryActionText !== '' && secondaryActionText !== null && (
              <Button
                text={secondaryActionText}
                className={`${secondaryActionColor} ${hasRoundCorners ? 'round-corners' : ''}`}
                to={!isSecondaryActionTargetBlank ? secondaryActionTo : ''}
                onClick={isSecondaryActionTargetBlank ? () => openInNewTab(secondaryActionTo) : secondaryActionOnClick}
              />
            )}
          </CustomBlock>
        ) : null}

      </CustomBlock>
    </CustomBlock>
  );
};

ContentSection.propTypes = {
  // MAIN PROPS
  backgroundColor: PropTypes.string,
  cardBackgroundColor: PropTypes.string,

  // IMAGE PROPS
  imageSource: PropTypes.number.isRequired,

  // TITLE PROPS
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,

  // DESCRIPTION PROPS
  description: PropTypes.string,
  descriptionColor: PropTypes.string,

  // ACTION PROPS
  primaryActionText: PropTypes.string,
  primaryActionColor: PropTypes.string,
  primaryActionTo: PropTypes.string,
  primaryActionOnClick: PropTypes.func,

  secondaryActionText: PropTypes.string,
  secondaryActionColor: PropTypes.string,
  secondaryActionTo: PropTypes.string,
  secondaryActionOnClick: PropTypes.func,

  // FUNCTION PROPS

  // BOOLEAN PROPS
  isPrimaryActionTargetBlank: PropTypes.bool,
  isSecondaryActionTargetBlank: PropTypes.bool,
  isReversed: PropTypes.bool,
  isCentered: PropTypes.bool,
  isTitleUppercase: PropTypes.bool,
  hasRoundCorners: PropTypes.bool,
  hasCardBackgroundImage: PropTypes.bool,
  isTextOnly: PropTypes.bool,
  isFloatingCard: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isFullWidthUnpadded: PropTypes.bool,
};

ContentSection.defaultProps = {
  // MAIN PROPS
  backgroundColor: '',
  cardBackgroundColor: '',

  // IMAGE PROPS

  // TITLE PROPS
  titleColor: 'primary--clr',

  // DESCRIPTION PROPS
  description: '',
  descriptionColor: 'dark--clr',

  // ACTION PROPS
  primaryActionText: '',
  primaryActionColor: 'primary--bg',
  primaryActionTo: '',
  primaryActionOnClick: null,

  secondaryActionText: '',
  secondaryActionColor: 'secondary--bg',
  secondaryActionTo: '',
  secondaryActionOnClick: null,

  // FUNCTION PROPS

  // BOOLEAN PROPS
  isPrimaryActionTargetBlank: false,
  isSecondaryActionTargetBlank: false,
  isReversed: false,
  isCentered: false,
  isTitleUppercase: false,
  hasRoundCorners: false,
  hasCardBackgroundImage: false,
  isTextOnly: false,
  isFloatingCard: false,
  isFullWidth: false,
  isFullWidthUnpadded: false,
};

export default ContentSection;