/* eslint-disable react/self-closing-comp */
// DEPENDENCIES
import React, { useState } from 'react';
// COMPONENTS
// ICONS
import { HiSearch } from 'react-icons/hi';
// CUSTOM COMPONENTS
import Section from '../../../components/Section';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import TextBlock from '../../../components/TextBlock';
import InputBlock from '../../../components/InputBlock';
import ContentStrip from '../../../components/ContentStrip';
// ASSETS - BLOG

const BlogsAndReviewsListingPage = (props) => {
  const { cmsInfo, history } = props;
  const [isLoading] = useState(false);
  const [filteredBlogs, setFilteredBlogs] = useState(cmsInfo.pages.filter((x) => x.isBlog));

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const search = (searchTerm) => {
    setFilteredBlogs(cmsInfo.pages
      .filter((x) => x.isBlog)
      .filter((x) => x.name.toLowerCase().includes(searchTerm.toLowerCase())));
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <CustomBlock className="content-wrapper">
          <TextBlock
            isPadded
            isUppercase
            type="header"
            text="Blog Posts"
            color="secondary--clr"
          />

          {/* FILTERS BLOCK */}
          <Section hasNoContainer className="content-container--card-style--with-shadow">
            <ContentBlock>
              <CustomBlock className="search-block full-width">
                <InputBlock
                  placeholder="Look up blog titles e.g. What is Run Deal..."
                  iconLeft={<HiSearch className="mid-grey--clr" size={25} />}
                  onChange={(e) => search(e.target.value)}
                />
              </CustomBlock>
            </ContentBlock>
          </Section>

          {/* BLOGS */}
          <CustomBlock className="content-strip-container">
            {
              filteredBlogs.map((item) => (
                <ContentStrip
                  imageSource={`${process.env.REACT_APP_API_URL}Attachments/${item.bannerImageFilePath}`}
                  title={item.name}
                  leftBlockText={item.addedOn}
                  rightBlockText={`By: ${item.addedBy}`}
                  badgeText={new Date(item.addedOn).getTime() === today.getTime() ? 'New' : ''}
                  onClick={() => {
                    history.push(item.url);
                  }}
                />
              ))
            }
            {
              filteredBlogs.length === 0
              && (
                <CustomBlock className="flex-center mt-60">
                  <TextBlock
                    type="header"
                    text="No Blogs Available"
                    color="primary--clr"
                  />
                </CustomBlock>
              )
            }
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default BlogsAndReviewsListingPage;