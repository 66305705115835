// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Overlay from './Overlay';

const SideSheet = (props) => {
  const {
    isOpen,
    closeActionOnClick,
    backgroundColor,
    position,
    children
  } = props;

  const positions = {
    left: 'left',
    right: 'right'
  };

  return (
    <>
      {isOpen && <Overlay onClick={closeActionOnClick} />}
      <CustomBlock className={`side-sheet ${backgroundColor} ${position && positions[position]} ${isOpen ? 'open' : ''}`}>
        <CustomBlock className="content-container">
          {children}
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

SideSheet.propTypes = {
  // MAIN PROPS
  isOpen: PropTypes.bool,
  closeActionOnClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  position: PropTypes.string,
  children: PropTypes.element,
};

SideSheet.defaultProps = {
  isOpen: false,
  closeActionOnClick: (() => { }),
  backgroundColor: 'white--sbg',
  position: 'left',
  children: null,
};

export default SideSheet;