import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Settings/';

const getSettings = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetSettings`, constants.REQUIRE_INTERCEPTORS);

const getSettingById = async (id) => axiosInstance.get(`${ENDPOINTORIGIN}GetSettingById?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const getSettingByKey = async (key) => axiosInstance.get(`${ENDPOINTORIGIN}GetSettingByKey?key=${key}`, constants.REQUIRE_INTERCEPTORS);

const saveSetting = async (setting) => axiosInstance.put(`${ENDPOINTORIGIN}SaveSetting`, setting, constants.REQUIRE_INTERCEPTORS);

export {
  getSettings,
  getSettingById,
  getSettingByKey,
  saveSetting,
};