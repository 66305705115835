import * as Yup from 'yup';

export default Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required('Menu Item Name is required').max(250),
  url: Yup.string().required().max(250),
  isBannerVisible: Yup.bool().nullable(),
  bannerImageFilePath: Yup.string().nullable(),
  bannerColor: Yup.string().max(10),
  keywords: Yup.string().nullable().max(1500),
  description: Yup.string().nullable(),
  isActive: Yup.bool(),
});