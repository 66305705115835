// DEPENDENCIES
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// import _ from 'lodash';
// COMPONENTS
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const FileUploadBlock = forwardRef((props, ref) => {
  const { containerClassName, labelIdle } = props;

  return (
    <CustomBlock className={`${containerClassName}`}>
      <FilePond
        {...props}
        ref={ref}
        labelIdle={labelIdle}
      />
    </CustomBlock>
  );
});

FileUploadBlock.propTypes = {
  containerClassName: PropTypes.string,
  labelIdle: PropTypes.string,
};

FileUploadBlock.defaultProps = {
  containerClassName: '',
  labelIdle: 'Drag & Drop your files or <span class="filepond--label-action">Browse</span>',
};

export default FileUploadBlock;